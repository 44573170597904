export const STATIC_CROWD_TEXT = [
	'WHO ATE MY BURGER !??',
	'HEEEELGAAAAAAA !??!?!',
	'Whoa, that note was out of this world!',
	'Is this jazz or are we just jazzy?',
	'Keep the beat going, and I’ll keep cheering!',
	"That riff was sharper than my grandma's knitting needle!",
	'We definitely need more cowbell!',
	'What an exotic note, was it planned?',
	"Let’s rock and roll, you're doing great!",
	'That was a spicy note!',
	"Can we tune the air guitar too? It's too good!",
	'Keep calm and carry on tuning, it sounds amazing!',
	'Your energy beats my shower singing!',
	"Encore! Seriously, don't stop now.",
	'This band could use more glitter, you shine bright enough!',
	'Who mixed these tracks? They’re blending perfectly!',
	'Loving the experimental vibes tonight!',
	'Your sound is more futuristic than a dial-up modem!',
	"Nice beat, it's cooking up a storm!",
	"Can't wait for the solo, you've got fans here!",
	'My ears are in love with that last track.',
	'Even my cat would want to join in on those notes.',
	'Your music is my new favorite request!',
	'This band makes me want to leave all other music behind.',
	'Keep playing, it’s music to my ears!',
	'I didn’t know music could be this fun!',
	"You call that a hit? It's a home run!",
	'Is there an app to learn playing like you?',
	"I'd listen to you all day, every day.",
	'That melody really took me places!',
	'You guys rock harder than a garage band!',
	"Play it again, I just can't get enough!",
]
