import { mapToSelectable } from '../../misc/util'
import { MainScene } from '../scenes/MainScene'
import { ArrowSelect } from './ArrowSelect'
import { RubikText } from './RubikText'

export class SettingArrowSelect extends Phaser.GameObjects.Container {
	public arrowSelect: ArrowSelect
	constructor(scene: MainScene, x: number, y: number, title: string, selectables: string[], eventName: string) {
		super(scene, x, y)

		const titleText = new RubikText(scene, 0, -15, title, {
			align: 'center',
			fontSize: '42px',
			fontStyle: 'bold',
			strokeThickness: 5,
			stroke: 'black',
		}).setOrigin(0.5, 0.5)
		const arrowSelect = new ArrowSelect(scene, 0, 160, mapToSelectable(selectables), eventName, 130)
		this.arrowSelect = arrowSelect
		const titleBackground = scene.add.sprite(0, 0, 'Title_Flag02_Purple').setScale(1.5)

		if (scene.isBandLeader) {
			arrowSelect.showArrows()
		}

		this.add(titleBackground)
		this.add(titleText)
		this.add(arrowSelect)

		this.setVisible(false)

		scene.add.existing(this)
	}
}
