import { Selectable } from '../phaser/components/ArrowSelect'
import { RubikText } from '../phaser/components/RubikText'
import { MainScene } from '../phaser/scenes/MainScene'

const isClientSideLocal = () => {
	return window.location.host.includes('localhost')
}

export const getColyseusURL = () => {
	const isLocal = isClientSideLocal()
	const host = isLocal ? 'localhost:3000/colyseus' : window.location.host
	const socketPrefix = isLocal ? 'ws' : 'wss'
	return `${socketPrefix}://${host}`
}

const SLIDER_LENGTH = 450
const SLIDER_STEPS = 16
const STEP_VALUE = 100 / SLIDER_STEPS
const TRACK_HEIGHT = 14
const THUMB_RADIUS = 20

export const createSlider = (scene: MainScene, x: number, y: number, label: string, initialValue: number) => {
	// Constants for the slider

	const text = new RubikText(scene, x, y - 85, label, {
		fontSize: '38px',
	}).setOrigin(0.5, 0)

	// Create the track for the slider
	let track = scene.add
		.image(x, y, 'Slider_BasicTriple_Fill_Middle_Yellow')
		.setDisplaySize(SLIDER_LENGTH, TRACK_HEIGHT)

	// Create the thumb for the slider
	let thumb = scene.add.image(x, y, 'Slider_Icon01_icon2').setInteractive().setScale(0.5)

	// Enable dragging on the thumb
	scene.input.setDraggable(thumb)

	thumb.on('pointerover', () => thumb.setScale(0.66))
	thumb.on('pointerout', () => thumb.setScale(0.5))

	initialValue = Phaser.Math.Clamp(initialValue, 0, 100)

	// Calculate the initial position of the thumb based on the initialValue
	let initialStep = Math.round(initialValue / STEP_VALUE)
	let initialX = x - SLIDER_LENGTH / 2 + (SLIDER_LENGTH / SLIDER_STEPS) * initialStep

	// Set the initial position of the thumb
	thumb.x = initialX
	thumb.setData('value', initialValue)

	thumb.on('pointerdown', (_pointer, _localX, _localY, event) => {
		event.stopPropagation()
	})
	thumb.on('drag', (_pointer, dragX, _dragY, _event) => {
		let oldValue = thumb.data.get('value')

		thumb.x = Phaser.Math.Clamp(dragX, x - SLIDER_LENGTH / 2, x + SLIDER_LENGTH / 2)

		let stepSize = SLIDER_LENGTH / SLIDER_STEPS
		thumb.x = x - SLIDER_LENGTH / 2 + stepSize * Math.round((thumb.x - x + SLIDER_LENGTH / 2) / stepSize)

		let newValue = Math.round((thumb.x - x + SLIDER_LENGTH / 2) / stepSize) * STEP_VALUE
		thumb.data.set('value', newValue)

		if (oldValue !== newValue) {
			scene.sound.volume = newValue / 100
			scene.sound.play('Card_Game_Achievement_Shimmer_Short_03')
			// scene.events.emit(`${name}Changed`, newValue / 100)
		}
	})

	return { track, thumb, text }
}

export const mapToSelectable = (labels: string[]) => {
	const selectables: Selectable[] = []
	for (const label of labels) {
		selectables.push({ label })
	}
	return selectables
}

export const mapToSelectableInstrument = (labels: string[]) => {
	const selectables: Selectable[] = []
	for (const label of labels) {
		selectables.push({
			label,
			soundKey: `Selection_Instrument_${label.charAt(0).toUpperCase() + label.slice(1)}`,
			textureKey: `${label}_white`,
		})
	}
	return selectables
}

export const useCursorPointer = (scene: Phaser.Scene) => {
	scene.input.manager.canvas.style.cursor = "url('/img/cursor/hand_point.png') 20 20, default"
}

export const useCursorOpen = (scene: Phaser.Scene) => {
	scene.input.manager.canvas.style.cursor = "url('/img/cursor/hand_open.png') 20 20, pointer"
}

export const useCursorGrab = (scene: Phaser.Scene) => {
	scene.input.manager.canvas.style.cursor = "url('/img/cursor/hand_closed.png') 20 20, grab"
}
export const mapTemplateToTexture = (template: string) => {
	switch (template) {
		case 'intro': {
			return 'StageFrame_n_Yellow'
		}
		case 'chorus': {
			return 'StageFrame_n_Yellow'
		}
		case 'bridge': {
			return 'StageFrame_n_Yellow'
		}
		default: {
			return 'StageFrame_n_Yellow'
		}
	}
}
