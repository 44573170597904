import { createSlider } from '../../misc/util'
import { MainScene } from '../scenes/MainScene'
import { RubikText } from './RubikText'

export class Options extends Phaser.GameObjects.Container {
	constructor(scene: MainScene) {
		super(scene, scene.cameras.main.width / 2, scene.cameras.main.height / 2)
		this.setSize(1000, 800)
		this.setDepth(1000)

		const title = new RubikText(scene, 0, -this.y / 2, 'SETTINGS', {
			fontSize: '72px',
			align: 'center',
			stroke: 'black',
			fontStyle: 'bold',
			strokeThickness: 7,
		}).setOrigin(0.5, 0.5)

		const bg = scene.add
			.nineslice(0, 0, 'ItemFrame01_Single_Purple', undefined, 177 * 5.63, 188 * 4.23, 50, 50, 50, 50)
			.setOrigin(0.5, 0.5)

		const overlayBackground = new Phaser.GameObjects.Rectangle(
			scene,
			0,
			0,
			scene.cameras.main.width,
			scene.cameras.main.height,
			0x00000
		).setAlpha(0.7)

		const { track: volumeTrack, thumb: volumeThumb, text: volumeLabel } = createSlider(scene, 0, -80, 'Volume', 10)

		const accelerationText = new RubikText(scene, 0, 40, 'Enable Hardware Acceleration', {
			fontSize: '38px',
		}).setOrigin(0.5, 0.5)

		let isHardwareAccelerationEnabled = false
		const accelerationButtonText = scene.add.text(-295, 125, '❌', { fontSize: '32px' }).setOrigin(0.5, 0.5)
		scene.discordSdk.commands
			.encourageHardwareAcceleration()
			.then((data) => {
				if (data?.enabled) {
					isHardwareAccelerationEnabled = data.enabled
					accelerationButtonText.text = '✔️'
				}
			})
			.catch((_e) => {
				console.log('Whoops, something went wrong when attempting to enable hardware acceleration!')
			})

		const accelerationDescription = scene.add
			.text(
				75,
				135,
				'Details: Turns on Hardware Acceleration, which uses your GPU to make Discord smoother. You can enabled this by clicking on the button. If you want to disable it, click on "User Settings" -> "Chat" -> "Advanced" -> "Hardware Acceleration".',
				{ fontSize: '16px' }
			)
			.setWordWrapWidth(600)
			.setOrigin(0.5, 0.5)

		const accelerationButton = this.scene.add
			.nineslice(
				accelerationButtonText.x,
				accelerationButtonText.y,
				'Button01_Yellow',
				undefined,
				145,
				145,
				20,
				20
			)
			.setScale(0.5)
			.setInteractive()
		accelerationButton.on('pointerdown', async () => {
			const res = await scene.discordSdk.commands.encourageHardwareAcceleration()
			isHardwareAccelerationEnabled = res?.enabled
			if (isHardwareAccelerationEnabled) {
				accelerationButtonText.text = '✔️'
			} else {
				accelerationButtonText.text = '❌'
			}
		})
		accelerationButton.on('pointerover', () => {
			scene.sound.play('Card_Game_UI_Button_Light_Reverb_02')
			accelerationButton.setTint(0x2a1b3d)
		})
		accelerationButton.on('pointerout', () => {
			accelerationButton.clearTint()
		})

		const closeButton = scene.add
			.image(this.width / 2 - 10, -this.height / 2 + 3, 'Icon_PictoIcon_Close')
			.setScale(1.8)
			.setInteractive()
		closeButton.on('pointerdown', () => {
			this.toggleShow()
		})
		closeButton.on('pointerover', () => {
			scene.sound.play('Card_Game_UI_Button_Light_Reverb_02')
			closeButton.setTint(0x2a1b3d)
		})
		closeButton.on('pointerout', () => {
			closeButton.clearTint()
		})

		this.add(overlayBackground)
		this.add(bg)
		this.add([volumeTrack, volumeThumb, volumeLabel])
		this.add(title)
		this.add(closeButton)

		this.add(accelerationText)
		this.add(accelerationButton)
		this.add(accelerationButtonText)
		this.add(accelerationDescription)

		if (scene.discordSdk.platform !== 'desktop') {
			accelerationText.setVisible(false)
			accelerationButton.setVisible(false)
			accelerationButtonText.setVisible(false)
			accelerationDescription.setVisible(false)
		}

		this.setVisible(false)
		scene.add.existing(this)
	}

	public toggleShow() {
		if (this.visible) {
			this.hide()
		} else {
			this.show()
		}
	}

	private show() {
		this.setVisible(true)
	}

	private hide() {
		this.setVisible(false)
	}
}
