import { MainScene } from '../scenes/MainScene'
import { Card } from './Card'
import { PerformCard } from './PerformCard'
import { RubikText } from './RubikText'

export class PlayerCardsStack {
	private group: Phaser.GameObjects.Group
	private scene: MainScene

	constructor(scene: MainScene) {
		this.scene = scene
		this.group = scene.add.group()
	}
	destroyAll() {
		this.group.clear(true, true)
	}

	addSegmentCard(card: Card) {
		const segmentCardContainer = this.scene.add.container(
			this.scene.cameras.main.width - 160 + Phaser.Math.Between(-15, 15),
			this.scene.cameras.main.height - 160 + Phaser.Math.Between(-15, 15)
		)

		const segmentCardBack = this.scene.add
			.sprite(0, 0, 'card_back')
			.setDisplaySize(card.width, card.height)
			.setScale(card.scale)
		const segmentCarrdName = new RubikText(
			this.scene,
			0,
			0,
			this.scene.room.state.gameState.template[this.scene.room.state.gameState.round - 1],
			{ fontSize: '48px', stroke: 'black', strokeThickness: 5, align: 'center' }
		).setOrigin(0.5, 0.5)

		segmentCardContainer.add(segmentCardBack)
		segmentCardContainer.add(segmentCarrdName)
		this.group.add(segmentCardContainer)
		segmentCardContainer.setDepth(this.group.getLength())
	}

	collectPlayedCards() {
		window.setTimeout(() => {
			const placedCards = this.scene.children
				.getAll()
				.filter((child) => child.getData('played_card') === 'true') as Card[] | PerformCard[]

			for (let placedCard of placedCards) {
				this.add(placedCard)

				this.scene.add.tween({
					delay: Phaser.Math.Between(50, 300),
					targets: [placedCard],
					x: this.scene.cameras.main.width - 160 + Phaser.Math.Between(-15, 15),
					y: this.scene.cameras.main.height - 160 + Phaser.Math.Between(-15, 15),
					angle: Phaser.Math.Between(-15, 15),
					duration: 500,
				})
			}
		}, 200) // delay to fix issue with showing in performcard
	}

	add(card: Card | PerformCard) {
		card.setDisplaySize(200, 300)
		card.removeListener('pointerdown')
		card.removeListener('pointerover')
		card.removeListener('pointerout')

		if (card instanceof Card) {
			card.hideProgressCircle()
		}

		card.on('pointerout', () => {
			if (card.soundKey) {
				this.scene.sound.stopByKey(card.soundKey)
			}
		})

		card.on('pointerover', () => {
			if (card.soundKey) {
				this.scene.sound.play(card.soundKey, { volume: 1.3 })
			}
		})
		card.on('pointerdown', () => this.cycleCards())
		this.group.add(card)
		card.setDepth(this.group.getLength())
	}

	private cycleCards() {
		const cards = this.group.getChildren() as (Card | PerformCard)[]
		console.log(
			'Before cycling:',
			cards.map((card) => `${card.name}: ${card.depth}`)
		)

		if (cards.length > 0) {
			// Increment all card depths
			cards.forEach((card) => {
				card.setDepth(card.depth + 1)
			})

			// Find the card with the highest depth and reset it
			const maxDepth = Math.max(...cards.map((card) => card.depth))
			const cardToReposition = cards.find((card) => card.depth === maxDepth)
			if (cardToReposition) {
				cardToReposition.setDepth(1)
			}

			// Optionally re-sort and set depths to ensure sequential order
			cards.sort((a, b) => a.depth - b.depth)
			cards.forEach((card, index) => {
				card.setDepth(index + 1)
			})

			console.log(
				'After cycling:',
				cards.map((card) => `${card.name}: ${card.depth}`)
			)
		}
	}
}
