import { useCursorOpen, useCursorPointer } from '../../misc/util'
import { RubikText } from './RubikText'

export interface Selectable {
	label: string
	soundKey?: string
	textureKey?: string
}

export class ArrowSelect extends Phaser.GameObjects.Container {
	private selectedIndex = 0
	private selectables: Selectable[] = []

	private valueDisplayText: RubikText
	private displayIcon: Phaser.GameObjects.Image

	private leftArrow: Phaser.GameObjects.Sprite
	private rightArrow: Phaser.GameObjects.Sprite

	private eventName: string

	constructor(
		scene: Phaser.Scene,
		x: number,
		y: number,
		selectables: Selectable[],
		eventName: string,
		gap: number = 0,
		disableText: boolean = false
	) {
		super(scene, x, y)
		this.eventName = eventName
		this.setSize(100, 100)

		const leftArrow = scene.add
			.sprite(-this.width - gap, disableText ? 20 : -35, 'ArrowLeft')
			.setInteractive()
			.setVisible(false)
		const rightArrow = scene.add
			.sprite(this.width + gap, disableText ? 20 : -35, 'ArrowRight')
			.setInteractive()
			.setVisible(false)
		const valueDisplayText = new RubikText(scene, 0, -35, selectables[this.selectedIndex].label.toUpperCase(), {
			align: 'center',
			fontSize: '42px',
			strokeThickness: 5,
			stroke: 'black',
		}).setOrigin(0.5, 0.5)

		if (disableText) {
			valueDisplayText.setVisible(false)
		}

		const displayIcon = scene.add.sprite(0, 20, selectables[this.selectedIndex].textureKey).setDisplaySize(90, 90)

		this.displayIcon = displayIcon
		this.valueDisplayText = valueDisplayText
		this.selectables = selectables

		leftArrow.on('pointerdown', () => this.onLeftArrowClick())
		leftArrow.on('pointerover', () => this.onHover(leftArrow))
		leftArrow.on('pointerout', () => this.onHoverOut(leftArrow))

		rightArrow.on('pointerdown', () => this.onRightArrowClick())
		rightArrow.on('pointerover', () => this.onHover(rightArrow))
		rightArrow.on('pointerout', () => this.onHoverOut(rightArrow))

		this.add(leftArrow)
		this.add(rightArrow)
		this.add(valueDisplayText)
		this.add(displayIcon)

		this.leftArrow = leftArrow
		this.rightArrow = rightArrow

		this.forceValueUpdate(this.selectables[this.selectedIndex].label)
		scene.add.existing(this)
	}

	onHover(sprite: Phaser.GameObjects.Sprite) {
		useCursorOpen(this.scene)
		this.scene.sound.play('Card_Game_UI_Button_Light_Reverb_02')
		sprite.setTint(0x2a1b3d)
	}

	onHoverOut(sprite: Phaser.GameObjects.Sprite) {
		useCursorPointer(this.scene)
		sprite.clearTint()
	}

	onLeftArrowClick() {
		this.scene.sound.play('Card_Game_UI_Button_Light_01')
		if (this.selectedIndex - 1 < 0) {
			this.selectedIndex = this.selectables.length - 1
		} else {
			this.selectedIndex -= 1
		}
		this.updateValue()
	}

	onRightArrowClick() {
		this.scene.sound.play('Card_Game_UI_Button_Light_01')

		if (this.selectedIndex >= this.selectables.length - 1) {
			this.selectedIndex = 0
		} else {
			this.selectedIndex = this.selectedIndex + 1
		}
		this.updateValue()
	}

	updateValue() {
		this.scene.events.emit(this.eventName, { data: this.selectables[this.selectedIndex].label.toLowerCase() })
		if (this.selectables[this.selectedIndex].soundKey) {
			this.scene.sound.play(this.selectables[this.selectedIndex].soundKey ?? '')
		}
		if (this.selectables[this.selectedIndex].textureKey) {
			this.displayIcon = this.displayIcon.setTexture(this.selectables[this.selectedIndex].textureKey)
		}
		this.forceValueUpdate(this.selectables[this.selectedIndex].label)
	}

	forceValueUpdate(value: string) {
		const foundSelectable = this.selectables.find((selectable) => selectable.label === value)
		const foundSelectableIndex = this.selectables.findIndex((selectable) => selectable.label === value)
		if (foundSelectable) {
			this.selectedIndex = foundSelectableIndex
			this.valueDisplayText.text = this.normalizeText(this.selectables[this.selectedIndex].label.toUpperCase())
			if (this.selectables[this.selectedIndex].textureKey) {
				this.displayIcon = this.displayIcon.setTexture(this.selectables[this.selectedIndex].textureKey)
			}
		}
	}

	normalizeText(value: string | number) {
		const isNumber = !isNaN(Number(value))
		return isNumber ? (Number(value) + 1).toString() : value.toString()
	}

	showArrows() {
		this.leftArrow.setVisible(true)
		this.rightArrow.setVisible(true)
	}
	hideArrows() {
		this.leftArrow.setVisible(false)
		this.rightArrow.setVisible(false)
	}
}
