import { Player } from '@server/src/states/Player'
import Phaser from 'phaser'
import { getAuth } from '../../discord/auth'
import { MainScene } from '../scenes/MainScene'
import { DropZone } from './DropZone'

const DROPZONE_WIDTH = 200
const DROPZONE_HEIGHT = 300

const INNER_POS_Y = 100

export class DropZoneBoard extends Phaser.GameObjects.Container {
	constructor(scene: MainScene, x: number, y: number) {
		super(scene, x, y)
		scene.add.existing(this)

		this.hideAndDisable()

		let playerZones = Array.from(scene.room.state.players.values())
		playerZones = playerZones.filter((pl) => pl.userId !== getAuth().user.id)

		const zone = new DropZone(this.scene as MainScene, 0, INNER_POS_Y, DROPZONE_WIDTH, DROPZONE_HEIGHT).setScale(
			1.5
		)
		this.add(zone)
		this.add(zone.border)

		this.createOtherZones(playerZones)

		this.x = (scene.sys.game.config.width as number) / 2 - this.width / 2
	}

	private createOtherZones(playerZones: Player[]): void {
		const increment = 290
		for (let i = 0; i < playerZones.length; i++) {
			const positionX = ((i + 1) % 2 === 0 ? 1 : -1) * Math.ceil((i + 1) / 2) * increment

			const otherPlayer = playerZones[i]
			const otherPlayedZone = this.scene.add
				.sprite(positionX, INNER_POS_Y, 'BannerFrame03_Single_Navy')
				.setName(`dropzone_${otherPlayer.userId}`)
			this.add(otherPlayedZone)
		}
	}

	public enableMyDropzone() {
		if (this.getByName(`mydropzone`) as DropZone) {
			;(this.getByName(`mydropzone`) as DropZone).enableZone()
		}
	}

	public disableMyDropzone() {
		if (this.getByName(`mydropzone`) as DropZone) {
			;(this.getByName(`mydropzone`) as DropZone).disableZone()
		}
	}

	public hideAndDisable() {
		this.setVisible(false)
		this.setActive(false)
	}

	public showAndEnabled() {
		this.setVisible(true)
		this.setActive(true)
	}
}
