import { MainScene } from '../scenes/MainScene'
import { RubikText } from './RubikText'

export class PerformSats extends Phaser.GameObjects.Container {
	private textobj: RubikText

	constructor(scene: MainScene, x: number, y: number, title: string, text: string) {
		super(scene, x, y)

		const bg = scene.add.sprite(0, 0, 'Title_Flag02_Purple').setScale(1.5)

		const trophy = scene.add.sprite(0, -105, 'Icon_Trophy').setScale(1.2)

		const titleObj = new RubikText(scene, 0, -20, title, {
			fontSize: '42px',
			fontStyle: 'bold',
			stroke: 'black',
			strokeThickness: 5,
		}).setOrigin(0.5, 0.5)

		this.textobj = new RubikText(scene, 0, 140, text, {
			fontSize: '62px',
			fontStyle: 'bold',
			backgroundColor: 'rgba(0,0,0,0.5)',
			stroke: 'black',
			strokeThickness: 5,
			padding: { x: 24, y: 8 },
		}).setOrigin(0.5, 0.5)

		this.setSize(bg.width, bg.height)

		this.add(bg)
		this.add(titleObj)
		this.add(this.textobj)
		this.add(trophy)
		scene.add.existing(this)
	}

	public setText(text: string) {
		this.textobj.setText(text)
	}
}
