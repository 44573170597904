export class StageVideo {
	private videoKey: string
	public video: Phaser.GameObjects.Video

	constructor(scene: Phaser.Scene, name: string, videoKey: string) {
		this.videoKey = videoKey
		this.initializeVideo(scene, name)
	}

	private initializeVideo(scene: Phaser.Scene, name: string) {
		const centerX = scene.cameras.main.centerX
		const centerY = scene.cameras.main.centerY

		this.video = scene.add.video(centerX, centerY, this.videoKey).setName(name).disableInteractive()
		this.video.setDepth(-100)

		const scale = this.calcBandVideoScale(
			1920,
			1080,
			Number(scene.game.config.width),
			Number(scene.game.config.height)
		)

		this.video.setScale(scale).setOrigin(0.5, 0.5).setVisible(true)

		this.video.x = Number(scene.game.config.width) / 2
		this.video.y = Number(scene.game.config.height) / 2

		this.video.play(true)

		this.video.on('error', (_video: any, error: any) => {
			console.log('Error video:', _video)
			console.log('Error playing video:', error)
		})
	}

	private calcBandVideoScale(
		videoWidth: number,
		videoHeight: number,
		canvasWidth: number,
		canvasHeight: number
	): number {
		const scaleX = canvasWidth / videoWidth
		const scaleY = canvasHeight / videoHeight
		return Math.min(scaleX, scaleY)
	}
}
