import './style.css'
import './font.css'
import { MyGame } from './phaser/game'
import { phaserRuntimeConfig } from './phaser/phaser.config'
import { getAudioAssets, getHealth } from './api/gameApiClient'
import { ColyseusClient } from './colyseus/colyseus'
import { setupDiscordSdk } from './discord/authApiClient'

getHealth()
getAudioAssets().then(async (gameConfig) => {
	const discordSdk = await setupDiscordSdk()
	const colyseusClient = new ColyseusClient()
	const room = await colyseusClient.init(discordSdk)

	const game = new MyGame(phaserRuntimeConfig)
	game.scene.start('main', { ...gameConfig, room, discordSdk })
})
