import * as Phaser from 'phaser'
import { MainScene } from './scenes/MainScene'

export const audioContext = new AudioContext()
export const recordingDestination = audioContext.createMediaStreamDestination()
;(audioContext as any).name = 'wejam_audiocontext'

document.addEventListener(
	'click',
	function () {
		audioContext
			.resume()
			.then(() => {
				console.log('Playback resumed successfully')
			})
			.catch(() => {
				console.log('Playback resumed not successfully')
			})
	},
	{ once: true }
)

const baseRuntimeConfig: Phaser.Types.Core.GameConfig = {
	type: Phaser.AUTO,
	parent: 'phaser',
	scale: {
		mode: Phaser.Scale.FIT,
	},
	width: 1920,
	height: 1080,
	autoFocus: true,
	audio: {
		context: audioContext,
	},
	transparent: true,
	physics: {
		default: 'arcade',
		arcade: {
			gravity: { y: 300, x: 0 },
			debug: true,
		},
	},
}

export const phaserRuntimeConfig: Phaser.Types.Core.GameConfig = {
	...baseRuntimeConfig,
	scene: [MainScene],
}
