import Phaser from 'phaser'
import { MainScene } from '../scenes/MainScene'
import { RubikText } from './RubikText'

const cardOffsets = { x: -10, y: -20 } // Each card is offset slightly to the right and up
const numberOfCards = 4
export class CardDeck extends Phaser.GameObjects.Group {
	private redrawText: Phaser.GameObjects.Sprite
	constructor(scene: MainScene, key: string = 'card_back') {
		super(scene)

		// Start offscreen to the right
		const baseX = scene.scale.width + 150 // Now 100 pixels beyond the canvas width
		const baseY = scene.scale.height / 2 // Vertically centered

		for (let i = 0; i < numberOfCards; i++) {
			const x = baseX + i * cardOffsets.x
			const y = baseY + i * cardOffsets.y

			// Create each card offscreen to the right
			const card = new Phaser.GameObjects.Sprite(scene, x, y, key).setScale(0.2).setOrigin(0.5) // Start fully transparent
			card.setInteractive()

			this.add(card, true)

			if (i === numberOfCards - 1) {
				const redrawIcon = scene.add.sprite(x, y, 'Icon_PictoIcon_Resume').setName('text').setDepth(100)
				this.redrawText = redrawIcon
				this.add(this.redrawText, true)
			}
		}
	}

	public show() {
		this.getChildren().forEach((child, index) => {
			const card = child as Phaser.GameObjects.Sprite

			if (child.name === 'text') {
				card.setVisible(true)
				index--
			}
			const finalX = this.scene.scale.width - 110 + index * -20
			card.once('pointerdown', () => {
				;(this.scene as MainScene).room.send('redraw:cards')
				this.getChildren().forEach((child) => {
					if (child.name === 'text') {
						;(child as RubikText).setVisible(false)
					}
					const card = child as Phaser.GameObjects.Sprite
					card.removeAllListeners()
				})
			})
			this.scene.tweens.add({
				targets: card,
				x: finalX,
				ease: 'Power2',
				duration: 950,
				delay: index * 95,
			})
		})
	}

	public hide() {
		// Iterate through each child in the group and animate it
		this.getChildren().forEach((child, index) => {
			const card = child as Phaser.GameObjects.Sprite
			// Calculate the offscreen position to the right for fade-out
			const offscreenX = this.scene.scale.width + 100 + index * 10 // Adjust as needed

			// Animate card to fade out and move offscreen to the right
			this.scene.tweens.add({
				targets: card,
				x: offscreenX,
				ease: 'Power2',
				duration: 950,
				delay: index * 95, // Stagger the animation start for each card
			})
		})
	}
}
