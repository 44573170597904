import { SelectBlockMessage } from '@server/@types/messages'
import { useCursorGrab, useCursorOpen, useCursorPointer } from '../../misc/util'
import { MainScene } from '../scenes/MainScene'
import { DropZone } from './DropZone'
import { DropZoneBoard } from './DropZoneBoard'
import { RubikText } from './RubikText'

const cardScale = 0.28
const cardScaleHover = 0.32

export class Card extends Phaser.GameObjects.Container {
	// private glow: Phaser.GameObjects.Image
	public cardSprite
	private originalPosition: Phaser.Math.Vector2
	private hovered: boolean
	public snapped: boolean
	public text: string
	private particles: Phaser.GameObjects.Particles.ParticleEmitter

	private progressCircle: Phaser.GameObjects.Graphics
	private progressTween: Phaser.Tweens.Tween
	private soundIcon: Phaser.GameObjects.Sprite
	public sound: Phaser.Sound.WebAudioSound
	public soundKey: string

	constructor(
		scene: MainScene,
		x: number,
		y: number,
		key: string = 'card_back',
		text: string = '234',
		assetKey: string
	) {
		super(scene, x, y)
		this.soundKey = assetKey
		scene.add.existing(this)
		const cardSprite = new Phaser.GameObjects.Sprite(scene, 0, 0, key)
		this.add(cardSprite)
		this.cardSprite = cardSprite
		this.setName('card')
		this.setScale(cardScale / 2)
		this.setSize(cardSprite.width, cardSprite.height)
		this.text = text

		this.hovered = false
		this.snapped = false
		this.scene.input.setDraggable(this.setInteractive())

		this.progressCircle = this.scene.add.graphics().setScale(2)
		this.progressCircle.setVisible(false)

		const speed = 200
		this.particles = this.scene.add.particles(0, 0, 'spark', {
			lifespan: 1000,
			follow: this.cardSprite,
			speed: { min: -speed, max: speed },
			gravityY: 200,
			scale: { start: 0.8, end: 0 },
			blendMode: 'ADD',
			quantity: 1,
			color: [0x34126d],
			emitZone: {
				type: 'edge',
				source: new Phaser.Geom.Rectangle(
					-cardSprite.width / 2,
					-cardSprite.height / 2,
					cardSprite.width,
					cardSprite.height
				),
				quantity: 450,
			},
			frequency: 10,
		})
		this.add(this.particles)
		const gradSound = this.scene.sound.add('Card_Game_Achievement_Shimmer_Long_04')

		this.soundIcon = scene.add
			.sprite(0, 0, 'Icon_PictoIcon_Sound_on')
			.setVisible(false)
			.setTint(0xfee0a0)
			.setScale(2)

		this.on(
			'dragstart',
			(_pointer) => {
				if (this.snapped) return
				this.setZ(2000)

				gradSound.play('', { loop: true, volume: 0.3 })
				this.scene.tweens.killTweensOf(this)
				useCursorGrab(this.scene)
			},
			this
		)

		this.on(
			'dragend',
			(_pointer) => {
				useCursorPointer(this.scene)

				gradSound.stop()
				;(scene.dropzoneBoard as DropZoneBoard).getAll().forEach((child) => {
					if (child instanceof DropZone && child.getBounds().contains(this.x, this.y) && !child.disabled) {
						scene.tweens.killTweensOf(this)
						;(this as Card).angle = 0
						;(this as Card).setData('played_card', 'true')
						this.x = child.x + scene.dropzoneBoard.x + 4 // some padding for the sprite shadow
						this.y = child.y + scene.dropzoneBoard.y

						const emitter = this.scene.add
							.particles(0, 0, 'musicnote', {
								x: this.x,
								y: this.y,
								speed: { min: 200, max: 400 },
								angle: { min: 0, max: 360 },
								scale: { start: 1, end: 0 },
								blendMode: 'ADD',
								lifespan: 1000,
								quantity: 40,
								frequency: -1,
							})
							.setDepth(-50)

						emitter.explode()
						this.snapped = true
						child.disabled = true
						scene.sound.add(assetKey).stop()
						scene.sound.play('Card_Deal_Slap', { volume: 2 })

						this.scene.events.emit('player_played_card', {
							type: 'select:block',
							key: assetKey,
						} as SelectBlockMessage)
					}
				})

				if (!this.snapped) {
					// If not snapped to any zone, move back to original position
					this.x = this.originalPosition.x
					this.y = this.originalPosition.y
				}
			},
			this
		)

		this.on(
			'drag',
			(_pointer, dragX, dragY) => {
				if (this.snapped) return
				this.x = dragX
				this.y = dragY
			},
			this
		)

		if (!scene.sound.get(assetKey)) {
			this.sound = scene.sound.add(assetKey) as Phaser.Sound.WebAudioSound
		} else {
			this.sound = scene.sound.get(assetKey)
		}
		this.on('pointerover', () => {
			// this.setZ(2000)
			this.sound.play('', { volume: 1.3 })
			useCursorOpen(this.scene)
			this.startProgressCircle()
			if (!this.hovered) {
				this.hovered = true
				this.scene.sound.play('Card_Game_UI_Button_Light_Reverb_01')

				this.scene.tweens.add({
					targets: this,
					scale: cardScaleHover,
					ease: 'Power2',
					duration: 1000,
				})

				scene.room.send('select:block', {
					type: 'select:block',
					key: assetKey,
				} as SelectBlockMessage)
			}
		})

		this.on('pointerout', (_pointer) => {
			this.sound.stop()
			this.setZ(999)
			this.hideProgressCircle()
			useCursorPointer(this.scene)
			if (this.snapped) return
			this.hovered = false
			this.scene.tweens.add({
				targets: this,
				y: this.originalPosition.y, // Move back to the original Y position
				scale: cardScale, // Original scale
				ease: 'Power2',
				duration: 1000,
			})
		})

		this.on('pointerdown', () => {
			this.select()
		})

		if (text !== '') {
			const cardTextObj = new RubikText(scene, -160, -520, text.replace(/\$/g, ' '), {
				fontSize: '72px',
				color: '#32126C',
				fontStyle: 'bold',
				//stroke: '#000000',
				//strokeThickness: 2,
				align: 'left',
			})
				.setOrigin(0, 0.5)
				.setDepth(20)
			this.add(cardTextObj)
		}

		this.add(this.progressCircle)
		this.add(this.soundIcon)
	}

	private startProgressCircle() {
		this.progressCircle.clear()
		this.soundIcon.setVisible(true)
		this.progressCircle.lineStyle(0, 0xefe6b1, 1)
		this.progressCircle.beginPath()
		this.progressCircle.arc(0, 0, 100, Phaser.Math.DegToRad(0), Phaser.Math.DegToRad(360), false)
		this.progressCircle.strokePath()
		this.progressCircle.setVisible(true)
		this.progressTween = this.scene.tweens.add({
			targets: this.progressCircle,
			angle: 360,
			duration: this.sound.totalDuration * 1000,
			ease: 'Linear',
			onUpdate: (tween) => {
				const elapsed = tween.elapsed
				const duration = tween.duration
				const progress = elapsed / duration
				this.progressCircle.clear()
				this.progressCircle.lineStyle(25, 0xefe6b1, 1)
				this.progressCircle.beginPath()
				this.progressCircle.arc(0, 0, 100, Phaser.Math.DegToRad(0), Phaser.Math.DegToRad(360 * progress), false)
				this.progressCircle.strokePath()
			},
		})
	}

	public hideProgressCircle() {
		this.progressCircle.setVisible(false)
		this.soundIcon.setVisible(false)
		this.progressCircle.clear()
		if (this.progressTween) {
			this.progressTween.stop()
		}
	}

	public removeCardWithTween = () => {
		this.removeAllListeners()

		const shouldSmashToScreen = Phaser.Math.Between(0, 10)
		if (shouldSmashToScreen === 10) {
			this.setDepth(9000)
			this.scene.tweens.add({
				targets: this,
				y: Phaser.Math.Between(300, 600),
				x: Phaser.Math.Between(500, 1200),
				angle: Phaser.Math.Between(760, 920),
				scale: 0.6,
				ease: 'Power2',
				duration: 1000,
				onComplete: () => {
					if (this.scene) {
						this.scene.sound.play('Card_Game_Movement_Deal_Single_01', { volume: 5 })
						this.scene.tweens.add({
							targets: this,
							ease: 'Power2',
							duration: 3000,
							y: this.scene.cameras.main.height + 1000,
							onComplete: () => {
								this.particles.stop()
								this.particles.destroy()
								this.destroy()
							},
						})
					}
				},
			})
		} else {
			this.scene.tweens.add({
				targets: this,
				y: this.y - 200,
				angle: 360,
				scale: 0,
				ease: 'Power2',
				duration: 1000,
				onComplete: () => {
					this.particles.stop()
					this.particles.destroy()
					this.destroy()
				},
			})
		}
	}

	public select() {
		// this.glow.setVisible(true)

		this.scene.children.each((child) => {
			if (child instanceof Card && child !== this) {
				child.deselect()
			}
		})
	}

	public deselect() {
		// this.glow.setVisible(false)
	}

	public putToHandWithTween(x: number, y: number, angle: number) {
		this.originalPosition = new Phaser.Math.Vector2(x, y)
		this.scene.tweens.add({
			targets: this,
			x: x,
			y,
			scale: cardScale,
			angle: angle,
			ease: 'Power2',
			duration: 1000, // Duration of the animation in milliseconds
			onComplete: () => {
				// hack fix
				if (this.scene) {
					this.scene.tweens.add({
						targets: this,
						y: this.y - 10, // Move the card up slightly
						ease: 'Sine.easeInOut',
						duration: 2000,
						yoyo: true, // Makes the tween go back to its original state, creating a float effect
						repeat: -1, // -1 makes the tween repeat indefinitely
					})
				}
			},
		})
	}
}
