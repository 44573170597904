import { getAuth } from './auth'

// https://discord.com/developers/docs/reference#image-formatting-cdn-endpoints
export const buildUserAvatarURL = () => {
	const { avatar, id } = getAuth().user
	if (id && avatar) {
		return `https://cdn.discordapp.com/avatars/${id}/${avatar}.png?size=128`
	} else {
		return 'https://cdn.discordapp.com/embed/avatars/0.png'
	}
}

interface ActivityAttachmentResponse {
	attachment: {
		url: string
	}
}
export const uploadActivityAttachment = async (file: File) => {
	if (!file) return null
	try {
		const body = new FormData()
		body.append('file', file)
		const response = await fetch(
			`https://discord.com/api/v9/applications/${import.meta.env.VITE_DISCORD_CLIENT_ID}/attachment`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${getAuth().access_token}`,
				},
				body,
			}
		)
		const responseData = (await response.json()) as ActivityAttachmentResponse
		if (!response.ok) {
			throw new Error(JSON.stringify(responseData))
		}
		return responseData
	} catch (e) {
		console.log('An error occurred while uploading Activity Attachment: ' + e)
		return null
	}
}
