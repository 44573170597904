export const getFireworkWave = (scene: Phaser.Scene) => {
	return scene.add
		.particles(0, 0, 'spark', {
			lifespan: { min: 1000, max: 1100 },
			speed: { min: -200, max: -400 },
			angle: { min: -30, max: -130 },
			scale: { start: 2, end: 0 },
			color: [0x8a2be2, 0x9370db, 0x4b0082],
			quantity: 1,
			blendMode: 'ADD',
			frequency: -1,
		})
		.setName('FireWorkWave')
		.setDepth(-50)
}

export const getFireworkBlast = (scene: Phaser.Scene) => {
	return scene.add
		.particles(0, 0, 'spark', {
			lifespan: { min: 1000, max: 1600 },
			speedX: { min: -70, max: 70 },
			speedY: { min: -240, max: -600 },
			scale: { start: 2, end: 0 },
			angle: { min: -45, max: -95 },
			color: [0x8a2be2, 0x9370db, 0x4b0082],
			quantity: 1,
			blendMode: 'ADD',
			frequency: -1,
		})
		.setDepth(-50)
}

export const getFireworkLaser = (scene: Phaser.Scene) => {
	return scene.add
		.particles(0, 0, 'spark', {
			x: 0, // Start emitting from the bottom left corner
			y: scene.cameras.main.height, // Bottom of the screen
			speedX: { min: 300, max: 400 },
			speedY: { min: -300, max: -700 }, // Control the speed to tweak how far particles travel
			lifespan: { min: 1000, max: 3000 }, // How long each particle lives
			color: [0x8a2be2, 0x9370db, 0x4b0082],
			scale: { start: 1, end: 0 }, // Particles shrink over their lifespan
			alpha: { start: 0.8, end: 0 }, // Fade out before dying
			blendMode: 'ADD', // Blend mode for a glowing effect
			frequency: 10, // Frequency of emitting particles
			quantity: 1, // Number of particles emitted per burst
		})
		.setDepth(-50)
		.stop()
}

export const getFireworkLaserRight = (scene: Phaser.Scene) => {
	return scene.add
		.particles(0, 0, 'spark', {
			x: scene.cameras.main.width, // Start emitting from the bottom left corner
			y: scene.cameras.main.height, // Bottom of the screen
			speedX: { min: -300, max: -400 },
			speedY: { min: -300, max: -700 }, // Control the speed to tweak how far particles travel
			lifespan: { min: 1000, max: 3000 }, // How long each particle lives
			color: [0x8a2be2, 0x9370db, 0x4b0082],
			scale: { start: 1, end: 0 }, // Particles shrink over their lifespan
			alpha: { start: 0.8, end: 0 }, // Fade out before dying
			blendMode: 'ADD', // Blend mode for a glowing effect
			frequency: 10, // Frequency of emitting particles
			quantity: 1, // Number of particles emitted per burst
		})
		.setDepth(-50)
		.stop()
}
