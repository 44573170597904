import { DiscordSDK, DiscordSDKMock } from '@discord/embedded-app-sdk'
import { setAccessToken, setAuth } from './auth'

let discordSdk: DiscordSDK | DiscordSDKMock
const queryParams = new URLSearchParams(window.location.search)
const isEmbedded = queryParams.get('frame_id') != null

export const setupDiscordSdk = async () => {
	if (isEmbedded) {
		discordSdk = new DiscordSDK(import.meta.env.VITE_DISCORD_CLIENT_ID)
	} else {
		// We're using session storage for user_id, guild_id, and channel_id
		// This way the user/guild/channel will be maintained until the tab is closed, even if you refresh
		// Session storage will generate new unique mocks for each tab you open
		// Any of these values can be overridden via query parameters
		// i.e. if you set https://my-tunnel-url.com/?user_id=test_user_id
		// this will override this will override the session user_id value
		const mockUserId = getOverrideOrRandomSessionValue('user_id')
		const mockGuildId = getOverrideOrRandomSessionValue('guild_id')
		const mockChannelId = getOverrideOrRandomSessionValue('channel_id')

		discordSdk = new DiscordSDKMock(import.meta.env.VITE_CLIENT_ID, mockGuildId, mockChannelId)
		const discriminator = String(mockUserId.charCodeAt(0) % 5)

		discordSdk._updateCommandMocks({
			authenticate: async () => {
				return await {
					access_token: 'mock_token',
					user: {
						username: mockUserId,
						discriminator,
						id: mockUserId,
						avatar: '',
						public_flags: 1,
					},
					scopes: [],
					expires: new Date(2112, 1, 1).toString(),
					application: {
						description: 'mock_app_description',
						icon: 'mock_app_icon',
						id: 'mock_app_id',
						name: 'mock_app_name',
					},
				}
			},
		})
	}

	await discordSdk.ready()
	console.log('Discord SDK is ready')

	// Authorize with Discord Client
	const { code } = await discordSdk.commands.authorize({
		client_id: import.meta.env.VITE_DISCORD_CLIENT_ID,
		response_type: 'code',
		state: '',
		prompt: 'none',
		// More info on scopes here: https://discord.com/developers/docs/topics/oauth2#shared-resources-oauth2-scopes
		scope: [
			// "applications.builds.upload",
			// "applications.builds.read",
			// "applications.store.update",
			// "applications.entitlements",
			// 'bot',
			'identify',
			// "connections",
			// "email",
			// "gdm.join",
			'guilds',
			// "guilds.join",
			// "guilds.members.read",
			// "messages.read",
			// "relationships.read",
			'rpc.activities.write',
			// "rpc.notifications.read",
			// "rpc.voice.write",
			// 'rpc.voice.read',
			// "webhook.incoming",
		],
	})

	// Retrieve an access_token from your activity's server
	const response = await fetch('/api/token', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			code,
		}),
	})
	const { access_token } = await response.json()

	const auth = await discordSdk.commands.authenticate({
		access_token,
	})

	await discordSdk.commands.setActivity({
		activity: {
			type: 0,
			details: 'Jamming with Friends',
			state: 'Forming a Band',
		},
	})

	if (auth == null) {
		throw new Error('Authenticate command failed')
	} else {
		setAuth(auth)
		setAccessToken(auth.access_token)
	}

	return discordSdk
}

enum SessionStorageQueryParam {
	user_id = 'user_id',
	guild_id = 'guild_id',
	channel_id = 'channel_id',
}

function getOverrideOrRandomSessionValue(queryParam: `${SessionStorageQueryParam}`) {
	const overrideValue = queryParams.get(queryParam)
	if (overrideValue != null) {
		return overrideValue
	}

	const currentStoredValue = sessionStorage.getItem(queryParam)
	if (currentStoredValue != null) {
		return currentStoredValue
	}

	const randomString = Math.random().toString(36).slice(2, 10)
	sessionStorage.setItem(queryParam, randomString)
	return randomString
}
