import { GameConfigDto } from '@server/@types/dto'
import { getAuth } from '../discord/auth'

export const getAudioAssets = async () => {
	return fetch('/api/gameconfig').then(async (res) => {
		return (await res.json()) as GameConfigDto
	})
}

export const getHealth = async () => {
	return fetch('/api/health')
}

export const sendPerformVideoDirectMessage = async (mediaUrl: string) => {
	try {
		const response = await fetch('/api/video', {
			method: 'POST',
			body: JSON.stringify({
				mediaUrl,
				userId: getAuth().user.id,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		})
		if (!response.ok) {
			return null
		}
		return true
	} catch (e) {
		console.log(e)
		return null
	}
}
