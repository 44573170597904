import { MainScene } from '../scenes/MainScene'
import { SoundboardButton } from './SoundboardButton'

const shortCuts = ['A', 'S', 'D']
export class Soundboard extends Phaser.GameObjects.Container {
	constructor(scene: MainScene, soundsBoardItems: string[]) {
		super(
			scene,
			scene.cameras.main.width / 2 - soundsBoardItems.length * 140 + 280,
			scene.cameras.main.height + 150
		)

		for (let i = 0; i < soundsBoardItems.length; i++) {
			const button = new SoundboardButton(scene, i * 140, 0, 'soundboard_idle', soundsBoardItems[i], shortCuts[i])
			this.add(button)
		}

		this.setDepth(200)
		scene.add.existing(this)
	}

	public updateSoundKeys(soundboardItems: string[]) {
		let i = 0
		for (const btn of this.getAll() as SoundboardButton[]) {
			btn.updateSoundKey(soundboardItems[i])
			i++
		}
	}

	public show() {
		const finalY = this.scene.cameras.main.height - 75

		this.scene.tweens.add({
			targets: this,
			y: finalY,
			ease: 'Power2',
			duration: 950,
			delay: 1 * 95,
		})
	}

	public hide() {
		const y = this.scene.cameras.main.height + 150

		this.scene.tweens.add({
			targets: this,
			y,
			ease: 'Power2',
			duration: 950,
			delay: 1 * 95,
		})
	}
}
