import { mapTemplateToTexture } from '../../misc/util'
import { MainScene } from '../scenes/MainScene'
import { RubikText } from './RubikText'

const POS_Y = 350
const SEGMENT_WIDTH = 140

const segmentScale = 0.5
const segmentScaleEnd = 0.55

export class SongVisualizer extends Phaser.GameObjects.Container {
	private currentTween: Phaser.Tweens.Tween | null = null
	private currentIndex: number | null = null

	constructor(scene: MainScene, template: string[]) {
		super(scene, 0, 0)

		this.drawVisualizer(template)
		this.setVisible(false)

		this.setDepth(100)
		scene.add.existing(this)
	}

	drawVisualizer(template: string[]) {
		this.getAll().map((el) => {
			if (el.name === 'highlighter') return
			el.destroy()
		})
		let height
		for (let i = 0; i < template.length; i++) {
			const templateName = template[i]
			const segment = this.scene.add.container(i * SEGMENT_WIDTH, 0).setScale(segmentScale)
			const background = this.scene.add.image(0, 0, mapTemplateToTexture(templateName))
			height = background.height
			const title = new RubikText(this.scene, 0, -10, template[i], { fontSize: '42px' }).setOrigin(0.5, 0.5)
			const frameHighlighter = this.scene.add
				.sprite(0, 0, 'StageFrame_s')
				.setVisible(false)
				.setName('highlighter')
			segment.add(frameHighlighter)
			segment.add(background)
			segment.add(title)
			segment.setName(`segment_song_${i}`)
			segment.setAlpha(0.5)
			this.add(segment)
		}
		this.setSize(template.length * SEGMENT_WIDTH, height)
		this.setPosition(this.scene.cameras.main.width / 2 - this.width / 2 + SEGMENT_WIDTH / 2, POS_Y)
	}

	highlightSegment(index: number) {
		const segment = this.getByName(`segment_song_${index}`) as Phaser.GameObjects.Container

		if (this.currentTween && this.currentIndex !== index) {
			this.currentTween.stop()
		}

		if (segment) {
			if (this.currentTween) {
				this.currentTween.stop()
			}
			;(segment.getByName('highlighter') as Phaser.GameObjects.Sprite).setVisible(true)
			this.currentTween = segment.scene.tweens.add({
				targets: [segment],
				scaleX: segmentScale,
				scaleY: segmentScaleEnd,
				duration: 500,
				ease: 'Bounce.Out',
				yoyo: true,
				onStop: () => {
					if (segment.getByName('highlighter') as Phaser.GameObjects.Sprite) {
						;(segment.getByName('highlighter') as Phaser.GameObjects.Sprite).setVisible(false)
					}
				},
				loop: -1,
			})

			this.currentIndex = index
			segment.setAlpha(1)
		}
	}
}
