import { Player } from '@server/src/states/Player'
import Phaser from 'phaser'
import { PlayerSlot } from './PlayerSlot'

export class LobbyContainer extends Phaser.GameObjects.Container {
	private playerSlots: PlayerSlot[] = []
	private gap: number = 120 // Horizontal gap between players

	constructor(scene: Phaser.Scene, slotsCount: number) {
		super(scene, scene.cameras.main.width / 2, scene.cameras.main.height / 2 + 25)

		let totalWidth = 164 * 5
		totalWidth += this.gap * (slotsCount - 1)
		let currentX = -totalWidth / 2

		for (let i = 0; i < slotsCount; i++) {
			const newPlayer = new PlayerSlot(this.scene, currentX + 164 / 2, 0, i)
			currentX += 164 + this.gap
			this.playerSlots.push(newPlayer)
			this.add(newPlayer)
		}
		scene.add.existing(this)
	}

	seatPlayer(player: Player) {
		return this.playerSlots.find((player2) => {
			return player2.name === `seat_${player.slot}`
		})
	}

	async addLobbyPlayer(player: Player) {
		const seat = this.seatPlayer(player)
		const imageKey = `avatar_${player.userId}`
		const loader = this.scene.load.image(imageKey, player.avatarUri)
		seat.showTakenSeat(player.instrument)
		loader.start()
		loader.on('complete', () => {
			seat.setAvatar(imageKey)
		})
	}

	changeLobbyPlayer(player: Player) {
		const lobbyPlayer = this.playerSlots.find((player2) => `seat_${player.slot}` === player2.name)

		if (lobbyPlayer) {
			lobbyPlayer.updateInstrumentText(player.instrument)
		}
	}

	setBandLeader(slot: number, previousValue: number) {
		const lobbyPlayer = this.playerSlots.find((player2) => `seat_${slot}` === player2.name)
		const previousLead = this.playerSlots.find((player2) => `seat_${previousValue}` === player2.name)

		for (const slot of this.playerSlots) {
			slot.hideLead()
		}
		if (lobbyPlayer) {
			lobbyPlayer.showLead()
		}

		if (previousLead) {
			previousLead.hideLead()
		}
	}

	removeLobbyPlayer(player: Player) {
		const playerToRemove = this.playerSlots.find((player2) => `seat_${player.slot}` === player2.name)
		if (playerToRemove) {
			playerToRemove.showEmptySeat()
			playerToRemove.avatar.setTexture('DefaultAvatar').setDisplaySize(164, 164)
		}
	}

	hide() {
		this.setVisible(false)
	}

	show() {
		this.setVisible(true)
	}
}
