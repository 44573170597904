import Phaser from 'phaser'
import { useCursorGrab, useCursorOpen, useCursorPointer } from '../../misc/util'

export class SoundboardButton extends Phaser.GameObjects.Sprite {
	private soundKey: string
	constructor(scene: Phaser.Scene, x: number, y: number, textureKey: string, soundKey: string, shortCutKey: string) {
		super(scene, x, y, textureKey)

		this.soundKey = soundKey

		this.setOrigin(0.5, 0.5)
		this.setDisplaySize(100, 100)
		this.setName('cheer')
		scene.add.existing(this)

		this.setInteractive().on('pointerdown', () => {
			this.setTexture('soundboard_down')
			useCursorGrab(scene)
			this.handleClick()
		})
		scene.input.keyboard.on(`keydown-${shortCutKey}`, () => {
			this.handleClick()
		})
		this.setInteractive().on('pointerup', () => {
			useCursorPointer(scene)
			this.setTexture(textureKey)
		})
		this.on('pointerover', () => {
			useCursorOpen(this.scene)
		})
		this.on('pointerout', () => {
			useCursorPointer(this.scene)
		})

		this.setDepth(400)
	}

	private handleClick() {
		this.scene.events.emit('cheer', this.soundKey)
	}

	public updateSoundKey(soundKey: string) {
		this.soundKey = soundKey
	}
}
