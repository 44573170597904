import { MainScene } from '../scenes/MainScene'
import { getFireworkBlast, getFireworkLaser, getFireworkLaserRight, getFireworkWave } from './fireworkParticles'
import { getMusicNotesRain } from './musicNotesRain'

export class ParticleManager {
	private scene: MainScene
	private musicNoteRainEmitter: Phaser.GameObjects.Particles.ParticleEmitter
	private fireworkWaveEmitter: Phaser.GameObjects.Particles.ParticleEmitter
	private fireworkBlastEmitter: Phaser.GameObjects.Particles.ParticleEmitter
	private fireworkLaserEmitter: Phaser.GameObjects.Particles.ParticleEmitter
	private fireworkLaserEmitterRight: Phaser.GameObjects.Particles.ParticleEmitter

	constructor(scene: MainScene) {
		this.scene = scene

		this.musicNoteRainEmitter = getMusicNotesRain(scene)
		this.fireworkWaveEmitter = getFireworkWave(scene)
		this.fireworkBlastEmitter = getFireworkBlast(scene)
		this.fireworkLaserEmitter = getFireworkLaser(scene)
		this.fireworkLaserEmitterRight = getFireworkLaserRight(scene)

		this.startMusicNoteRain()
	}

	startMusicNoteRain() {
		this.scene.time.addEvent({
			delay: 1000,
			startAt: 990,
			callback: () => {
				this.musicNoteRainEmitter
					.setParticleGravity(Phaser.Math.Between(50, -50), Phaser.Math.Between(50, 200))
					.start()
			},
			loop: true,
		})
	}

	stopFireworklaser() {
		this.fireworkLaserEmitter.stop()
		this.fireworkLaserEmitterRight.stop()
	}

	startFireworkLaser() {
		this.fireworkLaserEmitter.start()
		this.fireworkLaserEmitterRight.start()
	}

	startFireworkWave() {
		this.scene.time.addEvent({
			delay: 400,
			startAt: 190,
			callback: () => {
				this.fireworkWaveEmitter.explode(
					40,
					this.scene.cameras.main.width / 2 - 400,
					this.scene.cameras.main.height
				)
			},
			repeat: 15,
		})

		this.scene.time.addEvent({
			delay: 400,
			startAt: 190,
			callback: () => {
				this.fireworkWaveEmitter.explode(
					40,
					this.scene.cameras.main.width / 2 + 400,
					this.scene.cameras.main.height
				)
			},
			repeat: 15,
		})
	}

	startFireworkBlast() {
		this.scene.time.addEvent({
			delay: 3000,
			startAt: 2950,
			callback: () => {
				this.fireworkBlastEmitter.explode(100, 100, this.scene.cameras.main.height)
				this.fireworkBlastEmitter.explode(
					100,
					this.scene.cameras.main.width - 100,
					this.scene.cameras.main.height
				)
			},
			repeat: 4,
		})

		this.scene.time.addEvent({
			delay: 3000,
			startAt: 2000,
			callback: () => {
				this.fireworkBlastEmitter.explode(100, 300, this.scene.cameras.main.height)
				this.fireworkBlastEmitter.explode(
					100,
					this.scene.cameras.main.width - 300,
					this.scene.cameras.main.height
				)
			},
			repeat: 4,
		})

		this.scene.time.addEvent({
			delay: 3000,
			startAt: 1000,
			callback: () => {
				this.fireworkBlastEmitter.explode(100, 500, this.scene.cameras.main.height)
				this.fireworkBlastEmitter.explode(
					100,
					this.scene.cameras.main.width - 500,
					this.scene.cameras.main.height
				)
			},
			repeat: 4,
		})
	}
}
