import Phaser from 'phaser'
import { useCursorOpen, useCursorPointer } from '../../misc/util'
import { MainScene } from '../scenes/MainScene'
import { RubikText } from './RubikText'

export class SpecialBandLeaderButton extends Phaser.GameObjects.Container {
	public background: Phaser.GameObjects.NineSlice
	public textObj: RubikText

	private originalX: number = 0
	constructor(
		scene: MainScene,
		x: number,
		y: number,
		name: string,
		eventName: string,
		buttonText: string,
		textureButton: string,
		audioClickKey: string = 'Card_Game_UI_Button_Light_Reverb_02'
	) {
		super(scene, x, y)
		this.originalX = x

		this.setName(name)
		this.textObj = new RubikText(scene, 0, -10, buttonText, {
			fontSize: '32px',
			fontStyle: 'bold',
			stroke: 'black',
			strokeThickness: 6,
		}).setOrigin(0.5, 0.5)
		this.setVisible(false)
		this.setActive(false)

		this.background = scene.add.nineslice(
			0,
			0,
			textureButton,
			undefined,
			this.textObj.width + 80,
			undefined,
			25,
			25
		)
		this.setSize(this.background.width, this.background.height)

		this.setInteractive()
			.on('pointerdown', () => {
				if (scene.isBandLeader) {
					this.scene.sound.play(audioClickKey)
					this.handleClick(eventName)
				}
			})
			.on('pointerover', () => {
				if (scene.isBandLeader) {
					useCursorOpen(this.scene)
					this.background.setTint(0x2a1b3d)
					this.scene.sound.play('Card_Game_UI_Button_Light_Reverb_02')
				}
			})
		this.on('pointerout', () => {
			useCursorPointer(this.scene)
			this.background.clearTint()
		})

		this.add(this.background)
		this.add(this.textObj)
		// meh
		this.updateText(this.textObj.text)

		scene.add.existing(this)
	}

	private handleClick(eventName: string) {
		this.scene.events.emit(eventName)
	}

	public disableAndHide() {
		this.setVisible(false)
		this.setActive(false)
	}
	public enableAndShow() {
		this.setVisible(true)
		this.setActive(true)
	}

	public updateText(text: string) {
		this.textObj.text = text
		this.background.setSize(this.textObj.width + 80, this.textObj.height)
		this.setSize(this.background.width, this.background.height)
		this.setX(this.originalX - this.width / 2 - 20)
	}
}
