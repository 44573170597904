import { DiscordSDK, DiscordSDKMock } from '@discord/embedded-app-sdk'
import { GameAsset } from '@server/@types/dto'
import { BandMember, BandUpdate, InitPlanningData, InitSettingsData } from '@server/@types/game'
import { InstrumentMessage, SelectBlockMessage, SoundMessage } from '@server/@types/messages'
import { Player } from '@server/src/states/Player'
import { GameState, State } from '@server/src/states/State'
import { Room } from 'colyseus.js'
import { getAuth } from '../../discord/auth'
import { useCursorPointer } from '../../misc/util'
import { Card } from '../components/Card'
import { CardDeck } from '../components/CardDeck'
import { Challenge } from '../components/Challenge'
import { DropZone } from '../components/DropZone'
import { DropZoneBoard } from '../components/DropZoneBoard'
import { GameRoundInfoDualText } from '../components/GameRoundInfoDualText'
import { InviteUserButton } from '../components/InviteUserButton'
import { LobbyContainer } from '../components/LobbyContainer'
import { LobbyContainerVertical } from '../components/LobbyContainerVertical'
import { Options } from '../components/Options'
import { OptionsButton } from '../components/OptionsButton'
import { PerformCard } from '../components/PerformCard'
import { PerformResult } from '../components/PerformResult'
import { PlayerCardsStack } from '../components/PlayedCardsStack'
import { PlayerSlot } from '../components/PlayerSlot'
import { RubikText } from '../components/RubikText'
import { SettingArrowSelect } from '../components/SettingArrowSelect'
import { ShareMomentButton } from '../components/ShareMomentButton'
import { SongVisualizer } from '../components/SongVisualizer'
import { Soundboard } from '../components/Soundboard'
import { SpecialBandLeaderButton } from '../components/SpecialBandLeaderButton'
import { SpeechBubble } from '../components/SpeechBubble'
import { SpeechBubbleManager } from '../components/SpeechBubbleManager'
import { ParticleManager } from '../particles/ParticleManager'
import { audioContext, recordingDestination } from '../phaser.config'
import { GameRecorder } from '../recording/GameRecorder'
import { BaseScene } from './BaseScene'

export class MainScene extends BaseScene {
	private cardDeck!: CardDeck
	private roundInfoDualText!: GameRoundInfoDualText

	private countdownText!: any
	private band!: any
	private lobby!: LobbyContainer
	private continueToSettingsBtn!: SpecialBandLeaderButton
	private restartButton!: SpecialBandLeaderButton
	private shareMomentButton!: ShareMomentButton
	private inviteUserButton!: InviteUserButton

	private startButton!: SpecialBandLeaderButton
	private performResult!: PerformResult

	public dropzoneBoard!: DropZoneBoard
	public room: Room<State>

	public updateModeSelect: SettingArrowSelect
	public updateDeckSelect: SettingArrowSelect
	public updateAdditionalInstrumentsSelect: SettingArrowSelect

	public discordSdk: DiscordSDK | DiscordSDKMock

	public isBandLeader: boolean = false
	public songVisualizer!: SongVisualizer

	public speechBubbleManager: SpeechBubbleManager
	public gameRecorder: GameRecorder

	public particleManager: ParticleManager
	public soundboard: Soundboard

	public cup: Phaser.GameObjects.Sprite
	public challengeModal: Challenge

	constructor() {
		super('main', { active: false })
	}

	preload() {
		super.preload()
	}

	create(): void {
		super.create()
		// await discordSdk.commands.getPlatformBehaviors();
		;(this.sound as Phaser.Sound.WebAudioSoundManager).destination.connect(recordingDestination)

		if (audioContext.state === 'suspended') {
			audioContext.resume()
		}

		this.discordSdk = (this.sys.settings.data as any).discordSdk as DiscordSDK | DiscordSDKMock
		this.gameRecorder = new GameRecorder(recordingDestination.stream, this.discordSdk)

		this.sound.setVolume(0.1)
		useCursorPointer(this)
		this.fadeIn()
		this.speechBubbleManager = new SpeechBubbleManager(this)
		this.particleManager = new ParticleManager(this)

		console.log(this.discordSdk)
		this.discordSdk.commands.getPlatformBehaviors().then((data) => {
			console.log(data)
		})
		this.add
			.sprite(120, this.cameras.main.height - 120, 'logo_transparent')
			.setOrigin(0.5, 0.5)
			.setDisplaySize(230, 230)

		this.challengeModal = new Challenge(this)

		const optionsMenu = new Options(this)
		new OptionsButton(this, optionsMenu)

		this.cup = this.add
			.sprite(1330, 830, '2024_WeJam_Icon_Cup')
			.on('pointerdown', () => {
				this.room.send('perform:cheer', { type: 'play_sound', key: 'Slurp' } as SoundMessage)
			})
			.setInteractive()

		this.updateModeSelect = new SettingArrowSelect(
			this,
			this.cameras.main.width / 2 - 350,
			450,
			'MODE',
			['beginner', 'advanced'],
			'update_mode'
		)
		this.updateDeckSelect = new SettingArrowSelect(
			this,
			this.cameras.main.width / 2 + 350,
			450,
			'DECK',
			['essentials', 'reggae', 'funky'],
			'update_deck'
		)
		this.updateAdditionalInstrumentsSelect = new SettingArrowSelect(
			this,
			this.cameras.main.width / 2,
			775,
			'Turns',
			['0', '1', '2', '3', '4'],
			'update_additional_instruments'
		)

		this.cardDeck = new CardDeck(this)
		this.roundInfoDualText = new GameRoundInfoDualText(
			this,
			this.cameras.main.width / 2,
			90,
			'YOUR BAND WILL BE KNOWN AS',
			''
		)

		this.songVisualizer = new SongVisualizer(this, Array.from(this.room.state.gameState.template))
		this.countdownText = new RubikText(this, this.cameras.main.width / 2, this.cameras.main.height / 2, '', {
			font: '162px',
			color: '#06001b',
			fontStyle: 'strong',
			stroke: 'gold',
			strokeThickness: 12,
		})
			.setOrigin(0.5)
			.setAlpha(0)
		this.events.on('player_played_card', (data: SelectBlockMessage) => this.room.send('player:played:card', data))
		this.events.on('cheer', (data: string) =>
			this.room.send('perform:cheer', {
				type: 'play_sound',
				key: data,
			} as SoundMessage)
		)
		this.events.on('update_mode', (data) => {
			this.room.send('update:mode', { data: data.data })
		})
		this.events.on('update_deck', (data) => {
			this.room.send('update:deck', { data: data.data })
		})
		this.events.on('update_additional_instruments', (data) => {
			this.room.send('update:additional_instruments', { data: parseInt(data.data) })
		})

		this.events.on('select_instrument', (data) => {
			this.room.send('click:instrument', { type: 'click:instrument', key: data.data } as InstrumentMessage)
		})

		this.soundboard = new Soundboard(this, Array.from(this.room.state.gameState.soundboard))

		this.performResult = new PerformResult(this)

		const boardX = 0 // Adjust as needed to center or position
		const boardY = this.cameras.main.height / 2 - 130

		// Create the drop zone board at the calculated position
		this.dropzoneBoard = new DropZoneBoard(this, boardX, boardY)
		console.log(this.discordSdk.guildId)

		this.shareMomentButton = new ShareMomentButton(this)
		this.inviteUserButton = new InviteUserButton(this)

		this.continueToSettingsBtn = new SpecialBandLeaderButton(
			this,
			this.cameras.main.width - 20,
			this.cameras.main.height - 95,
			'start_settings',
			'start_settings',
			`Continue`,
			'Button01_Purple',
			'Card_Game_UI_Notification_Clap_01'
		)
		this.continueToSettingsBtn.enableAndShow()

		this.startButton = new SpecialBandLeaderButton(
			this,
			this.cameras.main.width - 20,
			this.cameras.main.height - 95,
			'start_game',
			'start_game',
			`Start`,
			'Button01_Purple',
			'Card_Game_UI_Notification_Clap_01'
		)
		this.startButton.disableAndHide()

		this.events.on('start_settings', () => {
			this.room.send('request:settings')
		})

		this.events.on('start_game', () => {
			this.room.send('click:state:button', { data: 'lobby' })
		})

		this.restartButton = new SpecialBandLeaderButton(
			this,
			this.cameras.main.width - 20,
			this.cameras.main.height - 95,
			'restart',
			'restart',
			'Leave stage',
			'Button01_Purple'
		)

		this.events.on('restart', () => {
			this.room.send('click:state:button', { data: 'perform' })
		})

		this.lobby = new LobbyContainer(this, 5)
		// PROBLEM: seat all players that are connected before seating individual --> works?=
		this.updateSecondaryPhaseText(this.room.state.gameState.bandname)
		// room stuff
		this.room.onMessage('speechbubble', (payload: { data: { message: string } }) => {
			console.log(payload.data.message)
			if (this.room.state.gameState.phase === 'planning') {
				new SpeechBubble(this, 90, 350, payload.data.message, true, 6000)
			}
		})

		this.room.onMessage('band:played:card', (payload: { data: BandUpdate }) => {
			const bandUpdate = payload.data

			const bandContainer = this.children.getByName('bandcontainer') as Phaser.GameObjects.Container
			const memberContainers = bandContainer.getAll('name', 'membercontainer') as Phaser.GameObjects.Container[]

			for (const memberContainer of memberContainers) {
				memberContainer.list.forEach((child, _index) => {
					if (child.name === `bandmemberstate${bandUpdate.userId}`) {
						const slot = this.dropzoneBoard.getByName(`dropzone_${bandUpdate.userId}`) as DropZone
						// TODO: maybe not re-use performcard specific cards
						new PerformCard(
							this,
							memberContainer.x,
							memberContainer.y,
							slot.parentContainer.x + slot.x,
							slot.parentContainer.y + slot.y,
							0,
							`card_${bandUpdate.selectedBlock.split('_')[0].toLowerCase()}`,
							bandUpdate.text.replace(/\$/g, ' '),
							bandUpdate.selectedBlock
						)
							.setDisplaySize(slot.width, slot.height)
							.setData('played_card', 'true')
					}
				})
			}
		})

		this.room.onMessage('updated:deck', (data: { deck: string }) => {
			this.updateDeckSelect.arrowSelect.forceValueUpdate(data.deck)
		})

		this.room.onMessage('updated:additional_instruments', (data: { count: number }) => {
			this.updateAdditionalInstrumentsSelect.arrowSelect.forceValueUpdate(`${data.count}`)
		})

		this.room.onMessage('updated:mode', (data: { template: string; mode: string }) => {
			this.updateModeSelect.arrowSelect.forceValueUpdate(data.mode)
			this.dropzoneBoard.destroy()
			this.dropzoneBoard = new DropZoneBoard(this, boardX, boardY)
			this.dropzoneBoard.enableMyDropzone()
		})

		this.room.onMessage('settings', (payload: { data: InitSettingsData }) => {
			this.roundInfoDualText.updateTitleText('Choose your Gig')
			this.hideLobby()
			this.startButton.enableAndShow()
			this.updateModeSelect.setVisible(true)
			this.updateDeckSelect.setVisible(true)
			this.updateAdditionalInstrumentsSelect.setVisible(true)
			this.addBand(payload.data.band.members)
			this.band.updateLeader(this.room.state.gameState.bandLeaderId)
			this.roundInfoDualText.dice.setVisible(false)
			this.inviteUserButton.disableAndHide()

			new SpeechBubble(
				this,
				90,
				350,
				'For one or two players, select at least 2 "turns" to play one additional, random instrument!',
				true,
				6000
			)
		})

		this.room.onMessage('play:sound', (payload: SoundMessage) => {
			this.handleSoundPlay(payload)
		})

		this.room.onMessage('play:sound:client', (payload: SoundMessage) => {
			//this.sound.stopAll() // why?
			this.handleSoundPlay(payload)
		})

		this.room.onMessage('perform:cheer:all', (payload: SoundMessage) => {
			this.handleSoundPlay(payload)
		})

		this.room.state.gameState.template.onChange(() => {
			// TODO: fix fires per individual array items
			this.songVisualizer.drawVisualizer(Array.from(this.room.state.gameState.template))
		})

		this.room.state.gameState.listen('soundboard', (value) => {
			this.soundboard.updateSoundKeys(Array.from(value))
		})

		this.room.state.gameState.listen('bandLeaderId', (value, previousValue) => {
			if (this.room.state.gameState.phase === 'lobby') {
				const player = Array.from(this.room.state.players.values()).find((p) => p.userId === value)
				const previousValueP = Array.from(this.room.state.players.values()).find(
					(p) => p.userId === previousValue
				)
				if (player && previousValueP) {
					// previous leader exists
					this.lobby.setBandLeader(player.slot, previousValueP.slot)
				} else if (player) {
					// not previous leader exists
					this.lobby.setBandLeader(player.slot, 9999)
				}
				if (this.band) {
					this.band.updateLeader(value)
				}
			}

			if (getAuth().user.id === value) {
				this.isBandLeader = true
				this.continueToSettingsBtn.updateText('Continue')
				this.startButton.updateText('Start')
				this.updateModeSelect.arrowSelect.showArrows()
				this.updateDeckSelect.arrowSelect.showArrows()
				this.updateAdditionalInstrumentsSelect.arrowSelect.showArrows()
			} else {
				this.isBandLeader = false
				this.restartButton.setVisible(false)
				this.continueToSettingsBtn.updateText('Wait for the band leader to start')
				this.startButton.updateText('The band leader is choosing the mode')
				this.updateModeSelect.arrowSelect.hideArrows()
				this.updateDeckSelect.arrowSelect.hideArrows()
				this.updateAdditionalInstrumentsSelect.arrowSelect.hideArrows()
			}
		})

		this.room.state.players.onAdd(async (player, _key) => {
			await this.handlePlayerJoin(player)
			console.log(`${player.userId} joining with assigned slot ${player.slot}`)
			player.onChange(() => {
				this.handlePlayerChange(player)
			})

			const slot = this.lobby.getByName(`seat_${player.slot}`) as PlayerSlot
			if (slot && player.userId === getAuth()?.user.id) {
				slot.arrowSelect.showArrows()
			}
		})

		this.room.state.players.onRemove((player, _key) => {
			window.location.reload()
			this.handlePlayerLeave(player)
		})

		this.room.onMessage('change:scenery', (payload: { data: { key: string } }) => {
			this.switchBackgroundVideo(payload.data.key)
		})

		this.room.onMessage('planning:cards', (payload: { data: InitPlanningData }) => {
			this.dealCards(payload.data.cards.api)
		})

		this.room.state.gameState.listen('turn', (_currentValue, _previousValue) => {
			if (this.room.state.gameState.phase === 'planning' && _currentValue !== _previousValue) {
				cardsPlayedGroup.collectPlayedCards()
				this.dropzoneBoard.enableMyDropzone()
			}
		})

		const cardsPlayedGroup = new PlayerCardsStack(this)
		this.room.state.gameState.listen('round', (_currentValue, _previousValue) => {
			if (this.room.state.gameState.phase === 'planning' && _currentValue !== _previousValue) {
				cardsPlayedGroup.collectPlayedCards()
				// FIX: segments cards at a fixed position
				//cardsPlayedGroup.addSegmentCard(placedCards[0])

				this.handlePlanningRoundUpdate(this.room.state.gameState)
				this.dropzoneBoard.enableMyDropzone()
			}
		})

		this.room.onMessage('band:update', (payload: { data: BandUpdate }) => {
			this.updateBand(payload.data)
		})

		this.room.state.gameState.listen('bandname', (currentValue) => {
			this.updateSecondaryPhaseText(currentValue)
		})

		this.room.state.gameState.listen('challenge', (currentValue) => {
			this.challengeModal.challengeText.text = `"${currentValue}"`
		})

		this.room.state.gameState.listen('phase', (_currentValue, _previousValue) => {
			console.log(this.children)
			if (_currentValue === 'planning' && _previousValue === 'lobby') {
				this.challengeModal.show()
				window.setTimeout(() => {
					this.challengeModal.hide()
					this.fadeScene(() => {
						const playerInstrument = Array.from(this.room.state.players.values()).find(
							(p) => p.userId === getAuth().user.id
						)
						if (playerInstrument) {
							this.switchBackgroundVideo(`we_jam_${playerInstrument.instrument.toLowerCase()}`)
						}

						this.dropzoneBoard.destroy()
						this.dropzoneBoard = new DropZoneBoard(this, boardX, boardY)
						this.dropzoneBoard.enableMyDropzone()
						this.handlePlanningRoundUpdate(this.room.state.gameState)
						this.startButton.enableAndShow()
						this.cup.setVisible(false)
						this.continueWithPlanningPhase()
					})
				}, 6000)
			} else if (_currentValue === 'perform' && _previousValue === 'planning') {
				cardsPlayedGroup.destroyAll()
				this.cameras.main.shake(6000, 0.002)
				this.discordSdk.commands.setActivity({
					activity: {
						type: 0,
						details: 'Jamming with Friends',
						state: 'Performing a gig',
					},
				})
				window.setTimeout(() => {
					this.fadeScene(() => {
						this.particleManager.startFireworkLaser()
						window.setTimeout(() => {
							this.particleManager.stopFireworklaser()
						}, 6000)

						this.sound.play('SFX_Constant_Cheer_1', { loop: true, volume: 0.18 })
						this.sound.play(`SFX_Random_Cheer_${Phaser.Math.Between(1, 3)}`, { volume: 0.19 })

						window.setTimeout(() => {
							this.gameRecorder.start()
							this.startCountDownAndPlaySong(this.room.state.gameState.song as any)
						}, 1000)
						this.updateSecondaryPhaseText(this.room.state.gameState.bandname)

						// improve
						for (const card of this.children.getAll('otherplayercard', true)) {
							card.destroy()
						}
						this.continueWithPerformPhase()
					})
				}, 2000)
			} else if (_currentValue === 'lobby' && _previousValue === 'perform') {
				this.cup.setVisible(true)
				this.fadeScene(() => {
					this.performResult.hide()
					this.switchBackgroundVideo('we_jam_lobby')
					this.continueWithLobbyPhase()
					for (const card of this.children.getAll('otherplayercard', false)) {
						card.destroy()
					}
					this.lobby.show()
				})
			}
		})

		this.sound.play('Bar_Ambience', { loop: true, volume: 0.4 })
	}

	public async handlePlayerJoin(player: Player) {
		await this.lobby.addLobbyPlayer(player)
	}

	public handlePlayerChange(player: Player) {
		this.lobby.changeLobbyPlayer(player)
	}

	public handlePlayerLeave(player: Player) {
		this.lobby.removeLobbyPlayer(player)
	}

	public addBand(bandMembers: BandMember[]) {
		// TODO: not good, re-use existing one.
		if (this.band) {
			this.band.destroy()
		}
		const band = new LobbyContainerVertical(this, bandMembers)
		this.band = band
	}

	public updateBand(bandUpdate: BandUpdate) {
		const bandContainer = this.children.getByName('bandcontainer') as Phaser.GameObjects.Container
		const memberContainers = bandContainer.getAll('name', 'membercontainer') as Phaser.GameObjects.Container[]

		for (const memberContainer of memberContainers) {
			memberContainer.list.forEach((child) => {
				if (child.name === `bandmemberstate${bandUpdate.userId}`) {
					if (bandUpdate.text) {
						;(child as Phaser.GameObjects.Text).setVisible(true)
					} else {
						;(child as Phaser.GameObjects.Text).setVisible(false)
					}
					;(child as Phaser.GameObjects.Text).text = bandUpdate.text.replace(/\$/g, ' ')
				}
			})
		}
	}

	public hideLobby() {
		this.lobby.setVisible(false)
		this.continueToSettingsBtn.setVisible(false)
		this.updateModeSelect.setVisible(false)
		this.updateDeckSelect.setVisible(false)
		this.updateAdditionalInstrumentsSelect.setVisible(false)
	}

	public showLobby() {
		this.lobby.setVisible(true)
		this.continueToSettingsBtn.setVisible(true)
		this.roundInfoDualText.dice.setVisible(true)
		this.inviteUserButton.enableAndShow()
	}

	public hideband() {
		this.band.setVisible(false)
	}

	public showBand() {
		this.band.setVisible(true)
	}

	public continueWithPlanningPhase() {
		this.roundInfoDualText.setVisible(true)
		this.dropzoneBoard.showAndEnabled()
		this.showBand()
		this.cardDeck.show()
		this.roundInfoDualText.updateTitleText(`Compose a song for "${this.room.state.gameState.challenge}"`)
		this.hideLobby()
		this.startButton.setVisible(false)
		this.continueToSettingsBtn.disableAndHide()
		this.sound.stopByKey('Bar_Ambience')
		this.sound.play('StadiumConcertIndo_BT041701', { loop: true, volume: 1 })
	}

	public continueWithPerformPhase() {
		this.dropzoneBoard.hideAndDisable()
		this.cardDeck.hide()
		this.showCheer()
		this.hideband()
		this.removeCardsWithTween()

		this.switchBackgroundVideo('we_jam_perform')

		this.roundInfoDualText.updateTitleText(`NOW PERFORMING LIVE ON STAGE`)
		this.countdownText.setVisible(true)

		this.sound.stopByKey('StadiumConcertIndo_BT041701')
	}

	public continueWithLobbyPhase() {
		this.roundInfoDualText.updateTitleText(`YOUR BAND WILL BE KNOWN AS`)
		this.hideCheer()
		this.showLobby()
		this.countdownText.setVisible(false)
		this.continueToSettingsBtn.enableAndShow()
		this.restartButton.disableAndHide()
		this.sound.stopAll()

		this.sound.play('Bar_Ambience', { loop: true, volume: 0.4 })
	}

	public startCountDownAndPlaySong(data: any) {
		const countdownValues = ['3', '2', '1']
		let currentCount = 0
		this.sound.play('Card_Game_Alert_Time_Up_01', { volume: 1.1 })

		const showNextCount = () => {
			if (currentCount < countdownValues.length) {
				this.countdownText.setText(countdownValues[currentCount])
				this.add.particles(this.cameras.main.width / 2, this.cameras.main.height / 2, 'spark', {
					blendMode: 'ADD',
					lifespan: 900,
					frequency: 32,
					scale: { start: 0.8, end: 0.1 },
					stopAfter: 16,
					color: [0xffd700],
					emitZone: {
						type: 'edge',
						source: new Phaser.Geom.Circle(0, 0, 180),
						quantity: 32,
					},
				})
				this.animateCountdownText(() => {
					currentCount++
					showNextCount()
				})
			} else {
				// abort if switching to lobby
				this.playSoundsSequentially(data)
				this.countdownText.setAlpha(0) // Hide the countdown text
			}
		}

		showNextCount()
	}

	animateCountdownText(onCompleteCallback) {
		// Reset properties before animation
		this.countdownText.setScale(1).setAlpha(1)

		// Animate growing larger and fading out
		this.tweens.add({
			targets: this.countdownText,
			scale: 2, // Grow larger
			alpha: 0, // Fade out
			ease: 'Cubic.easeOut', // Use an easing function for a smooth effect
			duration: 1000, // Animation duration in milliseconds
			onComplete: onCompleteCallback,
		})
	}

	public updateSecondaryPhaseText(text: string) {
		this.roundInfoDualText.updateRoundText(text)
	}

	public continueWithLobby() {
		this.roundInfoDualText.setVisible(false)
	}

	public handlePlanningRoundUpdate(gameState: GameState) {
		if (this.roundInfoDualText) {
			this.roundInfoDualText.updateRoundText(`${gameState.template[gameState.round]}`)
		}
	}

	removeCardsWithTween() {
		const currentCardInstances = this.children.getAll('name', 'card')
		if (currentCardInstances.length > 0) {
			currentCardInstances.map((existingCard: Card) => {
				existingCard.removeCardWithTween()
			})
		}
	}

	async dealCards(path: string) {
		const currentCardInstances = this.children.getAll('name', 'card')
		if (currentCardInstances.length > 0) {
			currentCardInstances.map((existingCard: Card) => {
				if (!existingCard.snapped) {
					;(existingCard as Card).removeCardWithTween()
				}
			})
		}

		const res = await fetch(path)
		const assets = (await res.json()) as GameAsset[]

		const topCard = this.cardDeck.getChildren()[this.cardDeck.getChildren().length - 1] as Phaser.GameObjects.Sprite
		const deckTopCardPosition = { x: topCard.x, y: topCard.y }

		// Constants for positioning and animation
		const cardSpacing = 220 // Space between the cards horizontally
		const screenHeight = this.cameras.main.height
		// const numberOfCards = 3 // Number of cards to deal
		const tiltAngle = 15 // Maximum tilt angle for the outer cards

		this.sound.play('Card_Game_Movement_Deal_Multiple_Fast_2')
		for (let i = 0; i < assets.length; i++) {
			let handY = screenHeight - 50 // Y position for the hand at the bottom of the screen

			// Calculate the x position for each card so they spread out with equal spacing
			// The center card remains at the center, the others are offset by `cardSpacing`
			const x = this.cameras.main.centerX + (i - 1) * cardSpacing

			// Instantiate the card at the deck's position
			let card = new Card(
				this,
				deckTopCardPosition.x,
				deckTopCardPosition.y,
				`card_${assets[i].instrument.toLowerCase()}`,
				assets[i].name,
				assets[i].key
			)

			// Calculate the tilt angle for each card
			// The center card (i=1) has no tilt, the others are symmetrically tilted
			let angle = 0 // Default angle for the center card
			if (i === 0) angle = -tiltAngle // Tilt the first card to the left
			if (i === 1) handY -= 35
			if (i === 2) angle = tiltAngle // Tilt the last card to the right

			// Animate the card moving to its final position in the hand
			card.putToHandWithTween(x, handY, angle)
		}
	}

	public switchBackgroundVideo(key: string) {
		if (this.children.getByName('background_video') && key) {
			;(this.children.getByName('background_video') as Phaser.GameObjects.Video).changeSource(key, true, true)
		}
	}

	public handleSoundPlay(payload: SoundMessage) {
		this.sound.play(payload.key)
	}

	public playSoundsSequentially(soundsByRound: any) {
		const rounds = Array.from(soundsByRound.keys()) as string[] // Get all round identifiers
		this.songVisualizer.setVisible(true)

		const playRound = (index: number) => {
			if (index >= rounds.length) {
				this.sound.play('SFX_End_Cheer_1')
				this.sound.add('SFX_Constant_Cheer_1').stop()

				const keys = Array.from(this.room.state.players.keys())
				if (keys.length === 0) {
					return undefined // No players available.
				}
				const randomKey = keys[Math.floor(Math.random() * keys.length)]

				this.performResult.show(
					this.room.state.gameState.cardsPlayed.toString(),
					this.room.state.players.get(randomKey).name, // TODO: fix best of show
					this.room.state.gameState.challenge
				)

				if (this.isBandLeader) {
					this.restartButton.setVisible(true)
				}
				this.songVisualizer.setVisible(false)
				this.speechBubbleManager.stopSpawmPerformBubbles()
				this.gameRecorder.stop()
				return // All rounds are done
			}

			const roundKey = rounds[index]
			const sounds = Array.from(soundsByRound.get(roundKey).tracks)
			this.songVisualizer.highlightSegment(index)

			console.log(`Playing round ${roundKey} with sounds: ${sounds.join(', ')}`)

			const soundPromises = sounds.map(
				(soundKey: string, i: number) =>
					new Promise<void>((resolve) => {
						const sound = this.sound.add(soundKey)
						const spawnLeft = index % 2 === 0

						if (soundKey.toLowerCase().includes('intro')) {
							this.particleManager.startFireworkWave()
						}

						if (soundKey.toLowerCase().includes('bridge')) {
							this.sound.play(`SFX_Random_Cheer_${Phaser.Math.Between(1, 3)}`, { volume: 0.5 })
							this.speechBubbleManager.spawnSpeechBubble(`OMG! It's ${soundKey}. My favourite!`)
						}

						if (soundKey.toLowerCase().includes('chorus')) {
							this.particleManager.startFireworkBlast()
						}

						const currentCard = new PerformCard(
							this,
							spawnLeft ? -300 : this.cameras.main.width + 300,
							300,
							spawnLeft ? 200 + i * 80 : this.cameras.main.width - 200 - i * 80,
							300 + i * 80,
							i * 10,
							`card_${soundKey.split('_')[0].toLowerCase()}`,
							`${soundKey.split('_')[3]}`
						)
						sound.on('complete', () => {
							currentCard.removeWithTween()
							resolve()
						})

						sound.play()
					})
			)

			// Wait for all sounds of the current round to finish playing
			Promise.all(soundPromises).then(() => {
				console.log(`Finished round ${roundKey}`)
				playRound(index + 1) // Play next round
			})
		}
		playRound(0) // Start with the first round
		this.speechBubbleManager.startSpawnPerformBubbles()
	}

	public fadeIn(duration: number = 1200) {
		this.cameras.main.fadeIn(duration)
	}

	public fadeScene(cb?: () => void, duration: number = 1200) {
		this.cameras.main.fadeOut(duration, 0, 0, 0, (_camera, progress) => {
			if (progress === 1) {
				this.cameras.main.fadeIn(duration)
				if (typeof cb === 'function') {
					cb()
				}
			}
		})
	}

	public showCheer() {
		this.soundboard.show()
	}

	public hideCheer() {
		this.soundboard.hide()
	}
}

interface KeyedArrays {
	[key: string]: [string]
}
