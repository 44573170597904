import { MainScene } from '../scenes/MainScene'
import { RubikText } from './RubikText'

export class SpeechBubble extends Phaser.GameObjects.Container {
	constructor(scene: MainScene, x, y, text, autoSpawn: boolean = true, duration: number = 4000) {
		super(scene, x, y)
		scene.add.existing(this)

		const bubblePadding = 10
		const arrowHeight = 30 // Adjust this if you want a different arrow size

		// Create the text object to measure dimensions
		const content = new RubikText(scene, 0, 0, text, {
			fontSize: 20,
			color: '#000000',
			align: 'center',
			wordWrap: { width: 300 }, // Use a temporary wrap width to calculate potential size
		})

		// Calculate bubble size based on text
		const textBounds = content.getBounds()
		const bubbleWidth = textBounds.width + bubblePadding * 3
		const bubbleHeight = textBounds.height + bubblePadding * 6

		// Create the graphics object for the bubble
		const bubble = scene.add.graphics({ x: x, y: y })

		// Bubble shadow
		bubble.fillStyle(0x222222, 0.5)
		bubble.fillRoundedRect(6, 6, bubbleWidth, bubbleHeight, 16)

		// Bubble color
		bubble.fillStyle(0xffffff, 1)

		// Bubble outline
		bubble.lineStyle(4, 0x565656, 1)
		bubble.strokeRoundedRect(0, 0, bubbleWidth, bubbleHeight, 16)
		bubble.fillRoundedRect(0, 0, bubbleWidth, bubbleHeight, 16)

		// Calculate arrow coordinates based on the new dynamic width
		const point1X = Math.floor(bubbleWidth / 7)
		const point1Y = bubbleHeight
		const point2X = Math.floor((bubbleWidth / 7) * 2)
		const point2Y = bubbleHeight
		const point3X = Math.floor(bubbleWidth / 7)
		const point3Y = Math.floor(bubbleHeight + arrowHeight)

		// Bubble arrow shadow
		bubble.lineStyle(4, 0x222222, 0.5)
		bubble.lineBetween(point2X - 1, point2Y + 6, point3X + 2, point3Y)

		// Bubble arrow fill
		bubble.fillTriangle(point1X, point1Y, point2X, point2Y, point3X, point3Y)
		bubble.lineStyle(2, 0x565656, 1)
		bubble.lineBetween(point2X, point2Y, point3X, point3Y)
		bubble.lineBetween(point1X, point1Y, point3X, point3Y)

		// Position the text within the bubble
		content.setPosition(x + bubbleWidth / 2 - textBounds.width / 2, y + bubbleHeight / 2 - textBounds.height / 2)

		this.add(bubble)
		this.add(content)

		if (autoSpawn) {
			scene.tweens.add({
				targets: [this],
				alpha: { from: 0, to: 1 },
				ease: 'Linear',
				duration: 500,
				onComplete: () => {
					scene.time.delayedCall(duration, () => {
						scene.tweens.add({
							targets: [this],
							alpha: 0,
							ease: 'Linear',
							duration: 500,
							onComplete: () => {
								this.destroy()
							},
						})
					})
				},
			})
		}
	}
}
