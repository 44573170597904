import { getAuth } from '../../discord/auth'
import { useCursorOpen, useCursorPointer } from '../../misc/util'
import { MainScene } from '../scenes/MainScene'

export class ShareMomentButton extends Phaser.GameObjects.Sprite {
	private mainScene: MainScene
	constructor(scene: MainScene) {
		super(scene, scene.cameras.main.width - 42, 125, 'Pictoicon_Share')
		this.mainScene = scene
		this.setOrigin(1, 0)
		this.setScale(0.16)
		this.setInteractive()
		this.setDepth(900)
		this.on('pointerdown', () => {
			this.shareSnapshot()
			this.scene.sound.play('Card_Game_UI_General_Echo_01')
		})
		this.on('pointerover', () => this.onHover())
		this.on('pointerout', () => this.onHoverOut())

		scene.add.existing(this)
	}

	onHover() {
		useCursorOpen(this.scene)
		this.scene.sound.play('Card_Game_UI_Button_Light_Reverb_02')
		this.setTint(0x2a1b3d)
	}

	shareSnapshot() {
		this.mainScene.sys.game.renderer.snapshot(async (image) => {
			let img = new Image()
			img.src = (image as HTMLImageElement).src

			img.onload = async () => {
				let canvas = document.createElement('canvas')
				canvas.width = img.width
				canvas.height = img.height
				let ctx = canvas.getContext('2d')
				ctx.drawImage(img, 0, 0, img.width, img.height)

				const dataURL = canvas.toDataURL('image/png')

				try {
					const response = await fetch(dataURL)
					const blob = await response.blob()

					const screenshotFile = new File([blob], 'screenshot.png', { type: 'image/png' })

					const body = new FormData()
					body.append('file', screenshotFile)

					const attachmentResponse = await fetch(
						`https://discord.com/api/applications/${import.meta.env.VITE_DISCORD_CLIENT_ID}/attachment`,
						{
							method: 'POST',
							headers: {
								Authorization: `Bearer ${getAuth().access_token}`,
							},
							body,
						}
					)

					const attachmentJson = await attachmentResponse.json()
					if (!attachmentJson.attachment.url) return
					const mediaUrl = attachmentJson.attachment.url

					await this.mainScene.discordSdk.commands.openShareMomentDialog({ mediaUrl })
				} catch (e) {
					// fail silently
				}
			}
		})
	}

	onHoverOut() {
		useCursorPointer(this.scene)
		this.clearTint()
	}

	public disableAndHide() {
		this.setVisible(false)
		this.setActive(false)
	}
	public enableAndShow() {
		this.setVisible(true)
		this.setActive(true)
	}
}
