import { GameConfigDto } from '@server/@types/dto'
import { State } from '@server/src/states/State'
import { Room } from 'colyseus.js'
import * as Phaser from 'phaser'
import { StageVideo } from '../components/StageVideo'
import { audioContext } from '../phaser.config'

export class BaseScene extends Phaser.Scene {
	public room: Room<State>

	constructor(key: string, options?: Phaser.Types.Scenes.SettingsConfig) {
		super({ key, ...options })
	}

	preload() {
		this.load.setBaseURL('')
		this.room = (this.sys.settings.data as any).room

		for (const file of (this.sys.settings.data as GameConfigDto).audio) {
			this.load.audio(file.key, file.filepath)
		}

		this.load.video('we_jam_bass', 'video/we_jam_bass.webm', true)
		this.load.video('we_jam_synth', 'video/we_jam_synth.webm', true)
		this.load.video('we_jam_drums', 'video/we_jam_drums.webm', true)
		this.load.video('we_jam_perc', 'video/we_jam_perc.webm', true)
		this.load.video('we_jam_keys', 'video/we_jam_keys.webm', true)

		this.load.video('we_jam_perform', 'video/we_jam_perform.webm', true)
		this.load.video('we_jam_lobby', 'video/we_jam_lobby.webm', true)

		this.load.image('spark', 'img/spark.png')
		this.load.image('musicnote', 'img/musicnote.png')

		this.load.image('card_back', 'img/cards/Back.png')
		this.load.image('card_drums', 'img/cards/Drums.png')
		this.load.image('card_synth', 'img/cards/Synth.png')
		this.load.image('card_bass', 'img/cards/Bass.png')
		this.load.image('card_keys', 'img/cards/Keys.png')
		this.load.image('card_perc', 'img/cards/Perc.png')

		this.load.image('ProfileFrame01_Border', 'img/ui/ProfileFrame01_Border.png')
		this.load.image('DefaultAvatar', 'img/ui/Default_Avatar.png')
		this.load.image('Button01_Purple', 'img/ui/Button01_Purple.png')
		this.load.image('ArrowLeft', 'img/ui/ArrowLeft.png')
		this.load.image('ArrowRight', 'img/ui/ArrowRight.png')
		this.load.image('Title_Flag02_Orange', 'img/ui/Title_Flag02_Orange.png')
		this.load.image('Title_Flag02_Purple', 'img/ui/Title_Flag02_Purple.png')
		this.load.image('Icon_PictoIcon_Setting', 'img/ui/Icon_PictoIcon_Setting.png')
		this.load.image('BannerFrame03_Single_Navy', 'img/ui/BannerFrame03_Single_Navy.png')
		this.load.image('Icon_PictoIcon_Lock_l', 'img/ui/Icon_PictoIcon_Lock_l.png')
		this.load.image('Icon_Dice_Yellow', 'img/ui/Icon_Dice_Yellow.png')
		this.load.image('Pictoicon_Dice', 'img/ui/Pictoicon_Dice.png')
		this.load.image('Icon_Dice', 'img/ui/Icon_Dice.png')
		this.load.image('Slider_Icon01_icon2', 'img/ui/Slider_Icon01_icon2.png')
		this.load.image('BorderFrame_Circle81', 'img/ui/BorderFrame_Circle81.png')
		this.load.image('Icon_PictoIcon_Exit_l', 'img/ui/Icon_PictoIcon_Exit_l.png')
		this.load.image('Pictoicon_Viking_Helmet', 'img/ui/Pictoicon_Viking_Helmet.png')
		this.load.image('Icon_PictoIcon_Sound_on', 'img/ui/Icon_PictoIcon_Sound_on.png')
		this.load.image('Pictoicon_Share', 'img/ui/Pictoicon_Share.png')
		this.load.image('Pictoicon_Friend_Add', 'img/ui/Pictoicon_Friend_Add.png')
		this.load.image('CardFrame06_Bg_Yellow', 'img/ui/CardFrame06_Bg_Yellow.png')

		this.load.image('Icon_Megaphone', 'img/ui/Icon_Megaphone.png')
		this.load.image('Icon_Quest', 'img/ui/Icon_Quest.png')
		this.load.image('Icon_Trophy', 'img/ui/Icon_Trophy.png')

		this.load.image('Icon_Crown', 'img/ui/Icon_Crown.png')
		this.load.image('soundboard_idle', 'img/ui/soundboard_idle.png')
		this.load.image('soundboard_down', 'img/ui/soundboard_down.png')
		this.load.image('Title_Ribbon_Bg_Yellow', 'img/ui/Title_Ribbon_Bg_Yellow.png')
		this.load.image('Icon_PictoIcon_Close', 'img/ui/Icon_PictoIcon_Close.png')
		this.load.image('Slider_Icon01_icon2', 'img/ui/Slider_Icon01_icon2.png')
		this.load.image('Slider_BasicTriple_Fill_Middle_Yellow', 'img/ui/Slider_BasicTriple_Fill_Middle_Yellow.png')
		this.load.image('Button01_Yellow', 'img/ui/Button01_Yellow.png')
		this.load.image('ItemFrame01_Single_Purple', 'img/ui/ItemFrame01_Single_Purple.png')
		this.load.image('Title_Flag01_Purple', 'img/ui/Title_Flag01_Purple.png')
		this.load.image('2024_WeJam_Icon_Cup', 'img/ui/2024_WeJam_Icon_Cup.png')
		this.load.image('Icon_PictoIcon_Resume', 'img/ui/Icon_PictoIcon_Resume.png')

		this.load.image('StageFrame_s', 'img/ui/StageFrame_s.png')
		this.load.image('StageFrame_n_Yellow', 'img/ui/StageFrame_n_Yellow.png')

		this.load.image('logo_transparent', 'img/logo.png')

		this.load.image('bass', 'img/icons/Icon_Bass_Black.png')
		this.load.image('drums', 'img/icons/Icon_Drums_Black.png')
		this.load.image('keys', 'img/icons/Icon_Keys_Black.png')
		this.load.image('perc', 'img/icons/Icon_Perc_Black.png')
		this.load.image('synth', 'img/icons/Icon_Synth_Black.png')

		this.load.image('bass_white', 'img/icons/Icon_Bass_White.png')
		this.load.image('drums_white', 'img/icons/Icon_Drums_White.png')
		this.load.image('keys_white', 'img/icons/Icon_Keys_White.png')
		this.load.image('perc_white', 'img/icons/Icon_Perc_White.png')
		this.load.image('synth_white', 'img/icons/Icon_Synth_White.png')

		this.load.image('Button03_Purple', 'img/ui/Button03_Purple.png')
		this.load.image('Button03_Yellow', 'img/ui/Button03_Yellow.png')
	}

	create() {
		this.addBackgroundVideo()
		this.addAudioContextResumeForce()

		this.hideLoadingSplash()
	}

	hideLoadingSplash() {
		document.getElementById('loading')?.remove()
	}

	addAudioContextResumeForce() {
		audioContext.onstatechange = () => {
			if (audioContext.state === 'suspended') {
				audioContext.resume()
			}
		}
	}

	addBackgroundVideo() {
		new StageVideo(this, 'background_video', 'we_jam_lobby')
	}
}
