export const getMusicNotesRain = (scene: Phaser.Scene) => {
	return scene.add
		.particles(0, -100, 'musicnote', {
			x: { min: 0, max: scene.cameras.main.width },
			quantity: 2,
			alpha: { start: 0.4, end: 0.15 },
			scale: { start: Phaser.Math.FloatBetween(0.5, 0.6), end: 0.3 },
			frequency: 1000,
			lifespan: 10000,
			gravityY: Phaser.Math.Between(-900, -500),
			emitting: false,
		})
		.setDepth(-50)
}
