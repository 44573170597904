import Phaser from 'phaser'
import { useCursorOpen, useCursorPointer } from '../../misc/util'
import { MainScene } from '../scenes/MainScene'
import { RibbonText } from './RibbonText'
import { RubikText } from './RubikText'

export class GameRoundInfoDualText extends Phaser.GameObjects.Container {
	private primaryTextObj: Phaser.GameObjects.Text
	private secondaryTextObj: RibbonText
	public dice: Phaser.GameObjects.Sprite

	constructor(scene: MainScene, x: number, y: number, primaryText: string, secondaryText: string) {
		super(scene, x, y)
		scene.add.existing(this)

		const primaryTextObj = new RubikText(scene, 0, -40, primaryText, {
			fontSize: '42px',
			fontStyle: 'bold',
			stroke: 'block',
			align: 'center',
			strokeThickness: 5,
		}).setOrigin(0.5, 0.5)
		this.primaryTextObj = primaryTextObj

		const secondary = new RibbonText(scene, 0, 90, secondaryText.toUpperCase())
		this.secondaryTextObj = secondary
		const bg = scene.add.nineslice(0, 0, 'Title_Flag01_Purple', undefined, 1200, 220, 440, 440, 100, 100)

		this.setSize(
			Math.max(primaryTextObj.width, this.secondaryTextObj.width, bg.width),
			primaryTextObj.height + this.secondaryTextObj.height
		)

		this.dice = scene.add
			.sprite(this.width / 2 - 70, -30, 'Icon_Dice')
			.setScale(0.7)
			.setOrigin(0.5, 0.5)
			.setInteractive()
			.on('pointerout', () => {
				useCursorPointer(this.scene)
				this.dice.setScale(0.7)
			})
			.on('pointerover', () => {
				this.dice.setScale(0.9)
				useCursorOpen(this.scene)
				this.scene.sound.play('Card_Game_UI_Button_Light_Reverb_02')
			})
			.on('pointerdown', () => {
				scene.room.send('play:sound:clients', { type: 'play_sound', key: 'Card_Game_Action_Magic_Sword_01' })
				scene.room.send('update:band')
			})

		this.setX(scene.scale.width / 2)

		this.add(bg)
		this.add(primaryTextObj)
		this.add(secondary)

		this.updateRoundText(secondaryText)
		this.add(this.dice)
	}

	public updateRoundText(text: string) {
		this.secondaryTextObj.updateText(text.toUpperCase())
		this.setSize(
			Math.max(this.primaryTextObj.width, this.secondaryTextObj.width),
			this.height + this.secondaryTextObj.height
		)
	}

	public updateTitleText(text: string) {
		this.primaryTextObj.text = text
	}
}
