import { MainScene } from '../scenes/MainScene'
import { RubikText } from './RubikText'

export class Challenge extends Phaser.GameObjects.Container {
	private overlay: Phaser.GameObjects.Rectangle
	public challengeText: RubikText
	constructor(scene: MainScene) {
		super(scene, scene.cameras.main.width / 2, scene.cameras.main.height + 1000)

		const bg = scene.add.nineslice(0, 0, 'CardFrame06_Bg_Yellow', undefined, 1200, 800, 30, 30, 90, 90)
		const title = new RubikText(scene, 0, -260, 'Gig request', {
			fontSize: '72px',
			stroke: 'black',
			fontStyle: 'bold',
			strokeThickness: 4,
		}).setOrigin(0.5, 0.5)

		const subTitle = new RubikText(scene, 0, -100, `Perform and compose today's song challenge`, {
			fontSize: '52px',
			stroke: 'black',
			fontStyle: 'bold',
			strokeThickness: 4,
			align: 'center',
		})
			.setOrigin(0.5, 0.5)
			.setWordWrapWidth(850)

		this.challengeText = new RubikText(scene, 0, 100, '', {
			fontSize: '72px',
			stroke: 'black',
			fontStyle: 'bold',
			strokeThickness: 6,
			backgroundColor: 'rgba(0,0,0,0.5)',
			padding: { x: 32, y: 12 },
			align: 'center',
		})
			.setOrigin(0.5, 0.5)
			.setWordWrapWidth(850)

		const questIcon = scene.add.sprite(0, 260, 'Icon_Quest').setScale(1.1)

		this.overlay = new Phaser.GameObjects.Rectangle(
			scene,
			scene.cameras.main.width / 2,
			scene.cameras.main.height / 2,
			scene.cameras.main.width,
			scene.cameras.main.height,
			0x00000
		)
			.setAlpha(0)
			.setVisible(false)
			.setDepth(500)

		//this.add(this.overlay)
		scene.add.existing(this.overlay)

		this.add(bg)
		this.add(title)
		this.add(subTitle)
		this.add(questIcon)
		this.add(this.challengeText)

		this.setDepth(900)

		scene.add.existing(this)
	}

	show() {
		this.scene.add.tween({
			targets: this,
			duration: 1000,
			y: this.scene.cameras.main.height / 2,
			onComplete: () => {
				console.log(this.y)
				console.log(this.x)
			},
		})

		this.overlay.setVisible(true).setInteractive()
		this.scene.add.tween({
			duration: 1000,
			targets: this.overlay,
			alpha: 0.7,
			onComplete: () => {
				console.log(this.overlay.visible)
				console.log(this.overlay.y)
			},
		})
	}

	hide() {
		this.scene.add.tween({
			targets: this,
			duration: 1000,
			y: this.scene.cameras.main.height + 1000,
			onComplete: () => {
				console.log(this.y)
				console.log(this.x)
				this.overlay.setVisible(false)
			},
		})

		this.overlay.setInteractive(false)
		this.scene.add.tween({
			duration: 1000,
			targets: this.overlay,
			alpha: 0,
			onComplete: () => {
				this.overlay.setVisible(false)
			},
		})
	}
}
