import { useCursorOpen, useCursorPointer } from '../../misc/util'
import { MainScene } from '../scenes/MainScene'
import { Options } from './Options'

export class OptionsButton extends Phaser.GameObjects.Sprite {
	constructor(scene: MainScene, optionsMenu: Options) {
		super(scene, scene.cameras.main.width - 40, 40, 'Icon_PictoIcon_Setting')
		this.setOrigin(1, 0)
		this.setScale(0.8)
		this.setInteractive()
		this.setDepth(900)
		this.on('pointerdown', () => {
			optionsMenu.toggleShow()
			this.scene.sound.play('Card_Game_UI_General_Echo_01')
			//scene.events.emit('options')
		})
		this.on('pointerover', () => {
			useCursorOpen(this.scene)
			this.onHover()
		})
		this.on('pointerout', () => {
			useCursorPointer(this.scene)
			this.onHoverOut()
		})

		scene.add.existing(this)
	}

	onHover() {
		this.scene.sound.play('Card_Game_UI_Button_Light_Reverb_02')
		this.setTint(0x2a1b3d)
	}

	onHoverOut() {
		this.clearTint()
	}
}
