import { BandMember } from '@server/@types/game'
import { MainScene } from '../scenes/MainScene'
import { RubikText } from './RubikText'

const BORDER_SIZE = 130

export class LobbyContainerVertical extends Phaser.GameObjects.Container {
	public nextYPosition: number = 0

	constructor(scene: MainScene, bandMembers: BandMember[]) {
		super(scene, 90, 120)
		this.setName('bandcontainer')

		for (let i = 0; i < bandMembers.length; i++) {
			const bandMember = bandMembers[i]

			const bandMemberContainer = scene.add.container(0, this.nextYPosition)
			bandMemberContainer.setName('membercontainer')

			const avatarTextureKey = `avatar_${bandMember.userId}`
			const playerImage = scene.add
				.image(0, 0, avatarTextureKey)
				.setDisplaySize(BORDER_SIZE - 10, BORDER_SIZE - 10)
			playerImage.setDepth(100)

			const textYOffset = -25

			const bandMemberStateText = new RubikText(scene, playerImage.displayWidth - 70, textYOffset, '', {
				fontSize: '32px',
				padding: { left: 50, right: 10, top: 10, bottom: 10 },
				backgroundColor: 'rgba(0,0,0,0.5)',
			}).setVisible(false)
			bandMemberStateText.setName(`bandmemberstate${bandMember.userId}`)

			const helmet = scene.add
				.sprite(60, -75, 'Icon_Crown')
				.setScale(0.2)
				.setVisible(false)
				.setName(`helmet_${bandMember.userId}`)
				.setTint(0xffd700)
				.setData('helmet', 'true')
			helmet.angle = 35

			const border = this.scene.add.image(0, 0, 'ProfileFrame01_Border').setDisplaySize(BORDER_SIZE, BORDER_SIZE)

			const instrumentCircle = this.scene.add
				.image(BORDER_SIZE / 3, BORDER_SIZE / 4 + 14, 'BorderFrame_Circle81')
				.setDisplaySize(BORDER_SIZE / 2, BORDER_SIZE / 2)

			const instrumentIcon = this.scene.add
				.image(BORDER_SIZE / 3, BORDER_SIZE / 4 + 12, bandMember.instrument.toLowerCase())
				.setScale(0.12)

			const circle = scene.add
				.graphics({
					x: this.x,
					y: this.y + this.nextYPosition,
				})
				.setVisible(false)
			circle.fillStyle(0xffffff, 1)
			circle.fillCircle(0, 0, BORDER_SIZE / 2 - 5)
			let mask = circle.createGeometryMask()
			playerImage.setMask(mask)
			this.add(playerImage)
			this.add(circle)
			;(bandMemberContainer as any).helmet = helmet
			bandMemberContainer.add(bandMemberStateText)
			bandMemberContainer.add(playerImage)
			bandMemberContainer.add(border)
			bandMemberContainer.add(instrumentCircle)
			bandMemberContainer.add(instrumentIcon)
			bandMemberContainer.add(helmet)

			this.nextYPosition += 160 // Adjust spacing as needed
			this.add(bandMemberContainer)
		}

		scene.add.existing(this)
	}

	updateLeader(bandleaderId: string) {
		if (!this.scene) {
			return
		}
		const bandContainer = this.scene.children.getByName('bandcontainer') as Phaser.GameObjects.Container
		const memberContainers = bandContainer.getAll('name', 'membercontainer') as Phaser.GameObjects.Container[]

		for (const memberContainer of memberContainers) {
			memberContainer.getAll().forEach((child) => {
				if (child.getData('helmet')) {
					;(child as any).setVisible(false)
				}
			})
			if (memberContainer.getByName(`helmet_${bandleaderId}`)) {
				;(memberContainer.getByName(`helmet_${bandleaderId}`) as any).setVisible(true)
			}
		}
	}
}
