import { MainScene } from '../scenes/MainScene'
import { RubikText } from './RubikText'

export class RibbonText extends Phaser.GameObjects.Container {
	private textObj: RubikText
	private ribbonObj: Phaser.GameObjects.NineSlice

	constructor(scene: MainScene, x: number, y: number, secondaryText: string) {
		super(scene, x, y)
		this.textObj = new RubikText(scene, 0, -5, '', {
			fontSize: '52px',
			color: 'white',
			align: 'center',
			fontStyle: 'bold',
			stroke: 'block',
			strokeThickness: 4,
		})
			.setAlpha(0)
			.setOrigin(0.5)
			.setName('round-name')
		this.ribbonObj = scene.add.nineslice(0, 10, 'Title_Flag02_Orange', undefined, 270, 96).setScale(1.1, 1.5)

		this.updateText(secondaryText)

		this.setSize(this.ribbonObj.width, this.ribbonObj.height)
		this.add(this.ribbonObj)
		this.add(this.textObj)
		scene.add.existing(this)
	}

	updateText(text: string) {
		this.textObj.setText(text)
		this.textObj.setAlpha(0)

		this.scene.tweens.add({
			targets: [this.ribbonObj],
			width: this.textObj.getBounds().width + 200,
			duration: 700,
			ease: 'sine.inout',
			onComplete: () => {
				this.textObj.setAlpha(1)
			},
			repeat: 0,
		})
	}
}
