import Phaser from 'phaser'
import { MainScene } from '../scenes/MainScene'

export class DropZone extends Phaser.GameObjects.Sprite {
	public disabled: boolean = true
	public border: Phaser.GameObjects.Graphics
	constructor(scene: MainScene, x: number, y: number, width: number, height: number) {
		super(scene, x, y, 'BannerFrame03_Single_Navy')
		this.setSize(width, height)

		this.setName(`mydropzone`)
		this.border = scene.add.graphics()
		this.drawBorder(width, height)
		this.setInteractive()

		scene.add.existing(this)
	}

	private drawBorder(width: number, height: number) {
		const borderWidth = 4
		const borderColor = 0xfd6948
		const borderRadius = 10

		this.border.clear()
		this.border.lineStyle(borderWidth, borderColor)
		this.border.strokeRoundedRect(this.x - width / 2, this.y - height / 2, width, height, borderRadius)
	}

	public enableZone() {
		this.disabled = false
		this.setInteractive(true)
	}

	public disableZone() {
		this.disabled = true
		this.setInteractive(false)
	}
}
