import { MainScene } from '../scenes/MainScene'
import { RubikText } from './RubikText'

const cardScale = 0.25
export class PerformCard extends Phaser.GameObjects.Container {
	public cardSprite
	public text: string
	public otherplayercard: boolean = false
	public soundKey?: string

	constructor(
		scene: MainScene,
		x: number,
		y: number,
		targetX: number,
		targetY: number,
		angle: number,
		texture: string = 'card_back',
		text: string = '',
		soundKey?: string
	) {
		super(scene, x, y)
		this.soundKey = soundKey
		const cardSprite = new Phaser.GameObjects.Sprite(scene, 0, 0, texture)
		this.add(cardSprite)
		this.setName('perform_card')
		if (soundKey) {
			this.otherplayercard = true
		}
		this.setScale(cardScale)
		this.angle = angle
		this.setSize(cardSprite.width, cardSprite.height) // Set the size of the container to match the sprite
		this.setInteractive()

		this.cardSprite = cardSprite
		this.text = text

		if (text !== '') {
			const cardTextObj = new RubikText(scene, -160, -520, text.replace(/\$/g, ' '), {
				fontSize: '72px',
				color: '#32126C',
				fontStyle: 'bold',
				//stroke: '#000000',
				//strokeThickness: 2,
				align: 'left',
			}).setOrigin(0, 0.5)

			this.add(cardTextObj)
		}

		this.on('pointerover', () => {
			if (soundKey) {
				scene.sound.play(soundKey, { volume: 1.3 })
			}
			this.setDepth(2000)
		})
		this.on('pointerout', () => {
			this.setDepth(0) // meh^^ problem in select
			if (soundKey) {
				scene.sound.stopByKey(soundKey)
			}
		})
		this.showWithTween(targetX, targetY, angle)

		scene.add.existing(this)
	}

	public removeWithTween = () => {
		this.scene.tweens.add({
			targets: this,
			y: -300,
			scale: 0.1,
			ease: 'Power2',
			duration: 1000,
			onComplete: () => {
				this.destroy()
			},
		})
	}

	public showWithTween(x: number, y: number, angle: number) {
		this.scene.tweens.add({
			targets: this,
			x,
			y,
			angle: angle,
			ease: 'Power2',
			duration: this.otherplayercard ? 100 : 1000, // tween race condition with collecting cards
			onComplete: () => {
				// hack fix
				if (this.scene && !this.otherplayercard) {
					this.scene.tweens.add({
						targets: this,
						y: this.y - 10,
						ease: 'Sine.easeInOut',
						duration: 2000,
						yoyo: true,
						repeat: -1,
					})
				}
			},
		})
	}
}
