import { MainScene } from '../scenes/MainScene'
import { PerformSats } from './PerformStats'

export class PerformResult extends Phaser.GameObjects.Container {
	private cardsPlayed: PerformSats
	private bestOfShow: PerformSats
	private challenge: PerformSats

	constructor(scene: MainScene) {
		super(scene, scene.cameras.main.width / 2, 450)

		this.cardsPlayed = new PerformSats(scene, -500, 150, 'CARDS PLAYED', '')
		this.bestOfShow = new PerformSats(scene, 0, 0, 'BEST OF SHOW', '')
		this.challenge = new PerformSats(scene, 500, 150, 'CHALLENGE', '')

		this.add(this.bestOfShow)
		this.add(this.cardsPlayed)
		this.add(this.challenge)

		this.setVisible(false)

		scene.add.existing(this)
	}

	public show(cardsplayed: string, bestOfShow: string, challenge: string) {
		this.cardsPlayed.setText(cardsplayed)
		this.bestOfShow.setText(bestOfShow)
		this.challenge.setText(challenge)
		this.setVisible(true)
	}

	public hide() {
		this.cardsPlayed.setText('')
		this.bestOfShow.setText('')
		this.challenge.setText('')
		this.setVisible(false)
	}
}
