import { DiscordSDK, DiscordSDKMock } from '@discord/embedded-app-sdk'
import { PlayerJoin } from '@server/src/states/Player'
import { State } from '@server/src/states/State'
import * as Colyseus from 'colyseus.js'
import { getAuth } from '../../src/discord/auth'
import { buildUserAvatarURL } from '../../src/discord/discordApiClient'
import { getColyseusURL } from '../../src/misc/util'

export class ColyseusClient {
	private client: Colyseus.Client

	constructor() {
		this.client = new Colyseus.Client(getColyseusURL())
	}

	public async init(discordSdk: DiscordSDK | DiscordSDKMock) {
		try {
			const avatar = buildUserAvatarURL()

			let roomName = 'Channel'

			if (discordSdk.channelId != null && discordSdk.guildId != null) {
				const channel = await discordSdk.commands.getChannel({ channel_id: discordSdk.channelId })
				if (channel.name != null) {
					roomName = channel.name
				}
			}
			return await this.client.joinOrCreate<State>('game', {
				roomName,
				channelId: discordSdk.channelId,
				userId: getAuth()?.user.id ?? Math.random().toString(36).slice(2, 10),
				name: getAuth()?.user.username ?? 'HeideltrautEUW',
				avatarUri: avatar,
				instrument: 'synth',
			} as PlayerJoin)
		} catch (e) {
			console.log('JOIN ERROR', e)
		}
	}
}
