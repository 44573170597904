import { DiscordSDK, DiscordSDKMock } from '@discord/embedded-app-sdk'
import { sendPerformVideoDirectMessage } from '../../api/gameApiClient'
import { uploadActivityAttachment } from '../../discord/discordApiClient'

export class GameRecorder {
	private mediaRecorder: MediaRecorder
	private chunks: BlobPart[] = []
	private videoStream: MediaStream
	private audioStream: MediaStream
	private discordSdk: DiscordSDK | DiscordSDKMock

	constructor(audioStream: MediaStream, discordSdk: DiscordSDK | DiscordSDKMock) {
		this.discordSdk = discordSdk
		const canvas = document.getElementsByTagName('canvas')[0] as HTMLCanvasElement
		this.audioStream = audioStream
		if (canvas) {
			this.videoStream = canvas.captureStream(60)
		} else {
			throw new Error('Where canvas?')
		}
	}

	start(): void {
		const combinedStream = new MediaStream([...this.videoStream.getTracks(), ...this.audioStream.getTracks()])
		this.mediaRecorder = new MediaRecorder(combinedStream, { mimeType: 'video/webm; codecs=vp9,opus' })
		// dig deeper into supported codes, although it will run an electron app anyway

		this.mediaRecorder.ondataavailable = (event) => {
			if (event.data.size > 0) {
				this.chunks.push(event.data)
			}
		}

		this.mediaRecorder.start()
	}

	async stop() {
		if (!this.mediaRecorder) {
			console.log('mediarecorder was not started')
			return
		}

		this.mediaRecorder.stop()
		this.mediaRecorder.onstop = async () => {
			this.handleStop()
		}
	}

	async handleStop() {
		const blob = new Blob(this.chunks, { type: 'video/webm;codecs=vp9,opus' })
		const buf = await blob.arrayBuffer()
		const videoFile = new File([buf], `${'WE_JAM'}.webm`, { type: 'video/webm;codecs=vp9,opus' })

		const attachmentResponse = await uploadActivityAttachment(videoFile)
		if (!attachmentResponse?.attachment?.url) return

		const mediaUrl = attachmentResponse.attachment.url
		await sendPerformVideoDirectMessage(mediaUrl)
		this.chunks = []
	}
}
