import { STATIC_CROWD_TEXT } from '../../misc/speechbubbles'
import { MainScene } from '../scenes/MainScene'
import { SpeechBubble } from './SpeechBubble'

const MIN_Y = 390
const MAX_Y = 400
export class SpeechBubbleManager {
	private scene: MainScene
	private positions: { x: number; y: number; isOccupied: boolean }[]

	private performBubblesTimer: Phaser.Time.TimerEvent

	constructor(scene: MainScene) {
		this.scene = scene
		this.positions = [
			{ x: 100, y: Phaser.Math.Between(MIN_Y, MAX_Y), isOccupied: false },
			{ x: 300, y: Phaser.Math.Between(MIN_Y, MAX_Y), isOccupied: false },
			{ x: 500, y: Phaser.Math.Between(MIN_Y, MAX_Y), isOccupied: false },
			{ x: 700, y: Phaser.Math.Between(MIN_Y, MAX_Y), isOccupied: false },
		]
	}

	spawnCrowdSpeechBubbles() {
		this.spawnSpeechBubble(STATIC_CROWD_TEXT[Phaser.Math.Between(0, STATIC_CROWD_TEXT.length - 1)])
	}

	startSpawnPerformBubbles() {
		this.performBubblesTimer = this.scene.time.addEvent({
			delay: Phaser.Math.Between(5100, 11100),
			startAt: 5000,
			callback: this.spawnCrowdSpeechBubbles,
			loop: true,
			callbackScope: this,
		})
	}

	stopSpawmPerformBubbles() {
		this.performBubblesTimer.remove()
	}

	spawnSpeechBubble(quote) {
		// Find a free position
		const freePositions = this.positions.filter((pos) => !pos.isOccupied)
		if (freePositions.length === 0) {
			console.log('No free positions available')
			return // No available position
		}

		// Randomly select a free position from the list of available ones
		const freePosition = freePositions[Math.floor(Math.random() * freePositions.length)]
		freePosition.isOccupied = true // Mark the position as occupied

		// Create the speech bubble at the position
		const bubble = new SpeechBubble(this.scene, freePosition.x, freePosition.y, quote, false)

		// Setup the fade out and free up the position
		this.scene.tweens.add({
			targets: [bubble],
			alpha: 0,
			ease: 'Linear',
			duration: 500,
			delay: 4000,
			onComplete: () => {
				bubble.destroy()
				freePosition.isOccupied = false // Free up the position
			},
		})
	}
}
