import { useCursorOpen, useCursorPointer } from '../../misc/util'
import { MainScene } from '../scenes/MainScene'

export class InviteUserButton extends Phaser.GameObjects.Sprite {
	private enabled: boolean = false
	constructor(scene: MainScene) {
		super(scene, scene.cameras.main.width - 40, 205, 'Pictoicon_Friend_Add')
		this.setOrigin(1, 0)
		this.setScale(0.2)
		this.setInteractive()
		this.setDepth(900)
		this.on('pointerdown', async () => {
			await scene.discordSdk.commands.openInviteDialog()
			this.scene.sound.play('Card_Game_UI_General_Echo_01')
		})
		this.on('pointerover', () => {
			useCursorOpen(this.scene)
			this.onHover()
		})
		this.on('pointerout', () => {
			useCursorPointer(this.scene)
			this.onHoverOut()
		})

		this.enabled = !!scene.discordSdk.guildId
		if (!this.enabled) {
			this.disableAndHide()
		}

		scene.add.existing(this)
	}

	onHover() {
		this.scene.sound.play('Card_Game_UI_Button_Light_Reverb_02')
		this.setTint(0x2a1b3d)
	}
	onHoverOut() {
		this.clearTint()
	}

	public disableAndHide() {
		this.setVisible(false)
		this.setActive(false)
	}
	public enableAndShow() {
		if (!this.enabled) return
		this.setVisible(true)
		this.setActive(true)
	}
}
