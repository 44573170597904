import { GameConfigDto } from '@server/@types/dto'
import Phaser from 'phaser'
import { mapToSelectableInstrument } from '../../misc/util'
import { ArrowSelect } from './ArrowSelect'

export class PlayerSlot extends Phaser.GameObjects.Container {
	public arrowSelect: ArrowSelect
	public avatar: Phaser.GameObjects.Image
	private lock: Phaser.GameObjects.Sprite
	private helmet: Phaser.GameObjects.Sprite

	constructor(scene: Phaser.Scene, x: number, y: number, slot: number) {
		super(scene, x, y)
		scene.add.existing(this)
		this.setName(`seat_${slot}`)

		// add default avatar
		const avatar = this.scene.add.image(0, 0, 'DefaultAvatar').setDisplaySize(164, 164).setDepth(10)

		const circle = scene.add
			.graphics({
				x: scene.cameras.main.width / 2 + this.x,
				y: scene.cameras.main.height / 2 + 15, // sync with with initial y value when calling constructor
			})
			.setVisible(false)
		circle.fillStyle(0xffffff, 1)
		circle.fillCircle(0, 0, 72)
		let mask = circle.createGeometryMask()
		avatar.setMask(mask)
		this.add(avatar)
		this.add(circle)
		this.avatar = avatar

		// add static border
		const border = this.scene.add.image(0, 0, 'ProfileFrame01_Border').setDisplaySize(164, 164).setDepth(200)

		const instrumentSelect = new ArrowSelect(
			scene,
			0,
			125,
			mapToSelectableInstrument((scene.sys.settings.data as GameConfigDto).instruments),
			'select_instrument',
			0,
			true
		)

		const helmet = this.scene.add.sprite(77, -105, 'Icon_Crown').setScale(0.35).setDepth(300)
		helmet.angle = 35
		helmet.setVisible(false)
		this.helmet = helmet

		const lock = scene.add.sprite(0, 150, 'Icon_PictoIcon_Lock_l').setScale(0.45)
		this.lock = lock
		this.arrowSelect = instrumentSelect
		this.arrowSelect.setVisible(false)
		this.add(instrumentSelect)
		this.add(lock)
		this.add(border)
		this.add(helmet)
	}

	public showLead() {
		this.helmet.setVisible(true)
	}

	public hideLead() {
		this.helmet.setVisible(false)
	}

	public showEmptySeat() {
		this.arrowSelect.setVisible(false)
		this.lock.setVisible(true)
	}

	public showTakenSeat(instrument: string) {
		//const border = this.scene.add.image(0, 0, 'ProfileFrame01_Border').setDisplaySize(164, 164).setDepth(200)
		//this.add(border)
		this.arrowSelect.setVisible(true)
		this.lock.setVisible(false)
		this.updateInstrumentText(instrument)
	}

	public setAvatar(texture: string) {
		this.avatar.setTexture(texture).setDisplaySize(164, 164).setDepth(10)
	}

	public updateInstrumentText(text: string) {
		if (this.arrowSelect) {
			this.arrowSelect.forceValueUpdate(text)
		}
	}
}
